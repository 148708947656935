import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import './RunFilterBoxComponent.scss';
interface Props {
  plannedBoxesCount: number;
  plannedRunsCount: number;
  inProgressBoxesCount: number;
  inProgressRunsCount: number;
  showDoneRuns: boolean;
  showDeletedVehicles: boolean;
  handleFilterChange: (e: InputSwitchChangeParams | DropdownChangeParams) => void;
}

const RunFilterBoxComponent = ({
  plannedBoxesCount,
  plannedRunsCount,
  inProgressBoxesCount,
  inProgressRunsCount,
  showDoneRuns,
  showDeletedVehicles,
  handleFilterChange,
}: Props) => {
  return (
    <Fieldset id="filter-box" className="line-filter p-mb-3 p-p-1 w-100">
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Planned</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">
            {`(${plannedBoxesCount} / ${plannedRunsCount})`}
          </span>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">In Progress</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">
            {`(${inProgressBoxesCount} / ${inProgressRunsCount})`}
          </span>
        </div>
        <div className="p-d-flex p-xs-12 p-sm-12 p-col-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Show Done</span>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <InputSwitch
              name="showDone"
              className={'p-as-center'}
              checked={showDoneRuns}
              onChange={e => handleFilterChange(e)}
            />
          </div>
        </div>
        <div className="p-d-flex p-xs-12 p-sm-12 p-col-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Show Archived</span>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <InputSwitch
              name="showDeletedVehicles"
              className={'p-as-center'}
              checked={showDeletedVehicles}
              onChange={e => handleFilterChange(e)}
            />
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default RunFilterBoxComponent;
