import React, {useContext, useState} from 'react';
import {AppContext, TwoDialog} from 'two-app-ui';
import {Button} from 'primereact/button';
import {FreightOrder, Run} from 'two-core';
import {InputText} from 'primereact/inputtext';

interface Props {
  orderAggregates: FreightOrder[];
  showDialog: boolean;
  onCancel: () => void;
  onRemoveFromRun: (reason: string) => void;
  onLeaveOnRun: (reason: string) => void;
}

export const RemoveDeliveredTasksDialog = ({
  showDialog,
  onCancel,
  onLeaveOnRun,
  onRemoveFromRun,
  orderAggregates,
}: Props) => {
  const context = useContext(AppContext);

  const [reason, setReason] = useState('');

  const validate = (reason: string) => {
    if (reason.trim() === '') {
      context.twoToast?.showError('Reason is required');
      return false;
    }
    return true;
  };

  const onLeaveOnRunClick = () => {
    if (validate(reason)) {
      onLeaveOnRun(reason);
    }
  };

  const onRemoveFromRunClick = () => {
    if (validate(reason)) {
      onRemoveFromRun(reason);
    }
  };

  const runsMap = new Map<number, Run>();
  for (const order of orderAggregates) {
    for (const run of order.runs ?? []) {
      if (!runsMap.has(run.id!)) {
        runsMap.set(run.id!, run);
      }
    }
  }
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={onCancel} />
      <Button
        label={`Remove from Run${runsMap.size > 1 ? '(s)' : ''}`}
        className={'p-mr-2'}
        onClick={onRemoveFromRunClick}
      />
      <Button label={`Leave on Run${runsMap.size > 1 ? '(s)' : ''}`} onClick={onLeaveOnRunClick} />
    </div>
  );
  let message;
  if (orderAggregates.length === 1) {
    if (runsMap.size === 1) {
      message =
        'The order is assigned to a run. Do you want to remove it from the run before delivering, or mark it delivered as part of the run?';
    } else {
      message =
        'The order is assigned to multiple runs. Do you want to remove it from the runs before delivering, or keep it on the runs?';
    }
  } else {
    message =
      'At least one of the selected orders is assigned to at least one run. Do you want to remove them from the runs before delivering, or keep in on the run?';
  }
  return (
    <TwoDialog
      header={`Remove order${orderAggregates.length > 1 ? '(s)' : ''} from run${runsMap.size > 1 ? '(s)' : ''}`}
      footer={footer}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '80vw', '576px': '90vw'}}
      draggable={false}
      onHide={onCancel}
    >
      <div>
        <div className="p-mx-5">{message}</div>
        <div className="p-fluid p-mt-3 p-mx-5">
          <div className="p-field p-grid">
            <label className="p-col-1">reason</label>
            <div className="p-col-11">
              <InputText onChange={e => setReason(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
    </TwoDialog>
  );
};
