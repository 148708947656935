import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, TwoDialog, TwoToast} from 'two-app-ui';
import {QueryParameter, Run, RunPatch, ScheduleEntry, Vehicle} from 'two-core';
import {Divider} from 'primereact/divider';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {messages} from '../../config/messages';
import RunsService from '../../services/RunsService';
import VehiclesService from '../../services/VehiclesService';
import SchedulesService from '../../services/SchedulesService';
import formats from '../../config/formats';

interface Props {
  showDialog: boolean;
  onHide: (runId?: number) => void;
}

interface State {
  loading: boolean;
  run: Run;
  vehicles: Vehicle[];
  selectedVehicle: Vehicle | undefined;
  date: Date | undefined;
}

class AddRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  vehiclesService: VehiclesService | null = null;
  schedulesService: SchedulesService | null = null;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      run: {
        name: '',
        stage: 'Draft',
        state_id: '',
      },
      vehicles: [],
      selectedVehicle: undefined,
      date: undefined,
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.vehiclesService = this.context.vehiclesService;
    this.schedulesService = this.context.schedulesService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    this.setState({loading: true});

    const sortBy: string[] = [];
    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
    };

    this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = (data?.records as Vehicle[]) ?? [];

        this.setState({
          vehicles: dataRecords,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const run: Run = this.state.run;
    const date = this.state.date;
    const vehicle = this.state.selectedVehicle;

    const currentStateId = localStorage.getItem('current state') ?? 'QLD';
    run.state_id = currentStateId;

    if (date || vehicle) {
      if (date && vehicle) {
        this.createRun(run, true);
      } else {
        this.twoToast?.showWarn('The date and vehicle need to be specified both or none for the schedule.');
      }
    } else {
      this.createRun(run, false);
    }
  }

  async createRun(run: Run, schedule: boolean) {
    this.setState({loading: true});
    return this.runsService
      ?.createRun(run)
      .then(data => {
        this.twoToast?.showSuccess('Run created successfully.');
        if (schedule) {
          return this.createScheduleEntry(data);
        } else {
          this.hideDialog(data?.id ?? 0);
        }
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, Run create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateRun(runId: string, run: RunPatch) {
    return this.runsService
      ?.updateRun(runId, run)
      .then(() => {})
      .catch(error => {
        console.error('error: ' + error);
      });
  }

  createScheduleEntry(run: Run) {
    const vehicle = this.state.selectedVehicle;
    const startDate = this.state.date;

    const runId = run.id;
    if (runId && vehicle && startDate) {
      const endDate = new Date(startDate);
      startDate.setHours(6);
      endDate.setHours(16);

      const scheduleEntry: ScheduleEntry = {
        type: 'run',
        vehicle_id: vehicle?.id ?? 0,
        run_id: runId,
        description: '',
        line_up: 1,
        updated_at: new Date(),
        start_at: startDate,
        end_at: endDate,
      };

      this.schedulesService
        ?.createScheduleEntry(scheduleEntry)
        .then(() => {
          const updatedRun: RunPatch = {stage: 'Scheduled'};
          this.updateRun(runId.toString(), updatedRun);

          this.twoToast?.showSuccess('Run Schedule created successfully.');

          this.hideDialog(run.id);
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, run schedule create failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  hideDialog(runId?: number) {
    this.setState({
      run: {name: '', stage: 'Draft', state_id: ''},
      selectedVehicle: undefined,
      date: undefined,
      loading: false,
    });
    this.props.onHide(runId);
    if (runId) {
      MessageService.sendMessage(messages.runCreate);
    }
  }

  setName(value: string) {
    const run = this.state.run;
    const updatedRun = {...run, name: value ?? ''};
    this.setState({run: updatedRun});
  }

  onChangeDate(e: CalendarChangeParams) {
    const value = e.value as Date;
    this.setState({date: value});
  }

  setSelectedVehicle(e: DropdownChangeParams) {
    const value = e.value;
    this.setState({selectedVehicle: value});
  }

  render() {
    const {run} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={run.name ?? ''}
                onChange={e => {
                  const name = e.target.value;
                  this.setName(name);
                }}
              />
            </span>
          </div>
        </div>

        <Divider />
        <span>Schedule</span>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
          <label htmlFor="vehicle" className="p-col-1">
            vehicle
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Dropdown
                className="p-d-flex w-100"
                filter
                value={this.state.selectedVehicle}
                options={this.state.vehicles}
                onChange={e => this.setSelectedVehicle(e)}
                optionLabel="name"
                showClear
              />
            </span>
          </div>
          <label htmlFor="date" className="p-col-1">
            date
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Calendar
                id="date"
                value={this.state.date}
                onChange={e => this.onChangeDate(e)}
                showIcon
                dateFormat={formats.calendarInputDate}
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Add Run'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        onShow={this.loadData}
        saveButtonTitle={'Add'}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddRunDialog;
