import React from 'react';
import {Button} from 'primereact/button';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, TwoDialog, TwoToast} from 'two-app-ui';
import {
  FreightOrder,
  MapOf,
  QueryParameter,
  Run,
  Stop,
  Task,
  Location,
  TaskType,
  TimeLineEvent,
  OrderPatch,
  TleLink,
  TleContentUnassigned,
  RunPatch,
  RunStage,
} from 'two-core';
import RunsService from '../../services/RunsService';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import AddRunDialog from './AddRunDialog';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import LocationsService from '../../services/LocationsService';
import SetLocationDialog from './SetLocationDialog';
import {MenuItemOptions} from 'primereact/menuitem';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import OrderListComponent from './OrderListComponent';
import OrdersTasksOnRunDialog from './OrdersTasksOnRunDialog';

export const currentStartingLocationsOption = 'Their Current Location(s)';
export const finalDestinationsOption = 'Their Final Destinations';
export const twoWarehouseOption = 'TWO Warehouse';
export const differentDestinationsOption = 'Different Destinations';
export const toHandoverLocationOption = 'To Handover Location';

export const startingLocationOptions: string[] = [currentStartingLocationsOption, twoWarehouseOption];

const finalLocationOptions: string[] = [finalDestinationsOption, twoWarehouseOption, differentDestinationsOption];

export function getFinalLocationOptions(orders: FreightOrder[]) {
  const state = localStorage.getItem('current state') ?? '';

  if (orders.length) {
    // check if some order is in final state
    const inFinalState = orders.some(order => {
      if (!order.route) {
        return false;
      }
      const currentStateRoutePartIndex = order.route[state].index;
      return Object.values(order.route).every(route => route.index <= currentStateRoutePartIndex);
    });

    if (!inFinalState) {
      return [toHandoverLocationOption, ...finalLocationOptions];
    }
  }
  return finalLocationOptions;
}

interface Props {
  showDialog: boolean;
  onHide: () => void;
  freightOrders: FreightOrder[];
  removeRunId?: string;
  isAdditionalRunAssignment?: boolean;
}

interface State {
  loading: boolean;
  runs: Run[];
  locations: Location[];
  locationsForDestination: Location[];
  warehouseLocations: Location[];
  stops: Stop[];
  selectedRun: Run | undefined;
  selectedStartingWarehouseLocation?: Location;
  selectedFinalWarehouseLocation: Location | undefined;
  createdRunId: number | undefined;
  selectedFreightOrders: FreightOrder[];
  ordersDestinationLocationsIdMap: MapOf<number>; //orderId => locationId
  showAddRunDialog: boolean;
  showSetLocationDialog: boolean;
  showCheckOrdersDialog: boolean;
  selectedStartingLocationOption: string;
  selectedFinalLocationOption: string;
  saveProcess: string;
}

class AssignOrdersToRunDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  runsService: RunsService | null = null;
  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  locationsService: LocationsService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  twoToast?: TwoToast;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      runs: [],
      locations: [],
      locationsForDestination: [],
      warehouseLocations: [],
      stops: [],
      selectedRun: undefined,
      selectedStartingWarehouseLocation: undefined,
      selectedFinalWarehouseLocation: undefined,
      createdRunId: undefined,
      selectedFreightOrders: [],
      ordersDestinationLocationsIdMap: {},
      showAddRunDialog: false,
      showCheckOrdersDialog: false,
      showSetLocationDialog: false,
      loading: true,
      selectedStartingLocationOption: currentStartingLocationsOption,
      selectedFinalLocationOption: finalDestinationsOption,
      saveProcess: '',
    };

    this.setSelectedRun = this.setSelectedRun.bind(this);
    this.save = this.save.bind(this);
    this.removeTasksForOrders = this.removeTasksForOrders.bind(this);
    this.setDefaultSelectedRun = this.setDefaultSelectedRun.bind(this);
    this.updateRun = this.updateRun.bind(this);
    this.loadRuns = this.loadRuns.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideAddRunDialog = this.hideAddRunDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.hideSetLocationDialog = this.hideSetLocationDialog.bind(this);
    this.changeAllOrdersDestinationLocation = this.changeAllOrdersDestinationLocation.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.setSelectedOrders = this.setSelectedOrders.bind(this);
    this.ordersTasksForRun = this.ordersTasksForRun.bind(this);
  }

  componentDidMount() {
    this.runsService = this.context.runsService;
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.locationsService = this.context.locationsService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.twoToast = this.context.twoToast;

    this.messageSendSubscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.runCreate) {
        this.loadRuns();
      }
    });
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.freightOrders !== prevProps.freightOrders) {
      const finalLocationOptions = getFinalLocationOptions(this.props.freightOrders);
      if (finalLocationOptions.includes(toHandoverLocationOption)) {
        this.setSelectedFinalLocationOption(toHandoverLocationOption);
      } else {
        this.setSelectedFinalLocationOption(finalDestinationsOption);
      }
    }
  }

  async loadData() {
    await this.loadRuns();
    await this.loadLocations();

    this.setState({loading: false});
  }

  setSelectedOrders(items: FreightOrder[]) {
    this.setState({selectedFreightOrders: items});
  }

  setSelectedRun(e: DropdownChangeParams) {
    const value = e.value as Run;
    this.setState({selectedRun: value});
  }

  save() {
    const {selectedRun} = this.state;
    if (selectedRun) {
      const ordersWithTasksOnRun = this.ordersTasksForRun(selectedRun);
      if (ordersWithTasksOnRun.length > 0) {
        this.setState({
          showCheckOrdersDialog: true,
        });
      } else {
        this.updateRun();
      }
    }
  }

  ordersTasksForRun(run: Run) {
    const ordersWithTasksInRun: FreightOrder[] = [];
    this.props.freightOrders.forEach((order: FreightOrder) => {
      if (run.tasks?.some(task => task?.freight_order_id === order.id)) {
        ordersWithTasksInRun.push(order);
      }
    });

    return ordersWithTasksInRun;
  }

  setSelectedStartingLocationOption(e: DropdownChangeParams) {
    const value = e.value as string;
    this.setState({
      selectedStartingLocationOption: value,
      selectedFreightOrders: [],
    });

    if (value === currentStartingLocationsOption) {
      this.setState({selectedStartingWarehouseLocation: undefined});
    } else if (value === twoWarehouseOption) {
      const location = this.state.warehouseLocations[0];
      if (location) {
        this.setState({
          selectedStartingWarehouseLocation: this.state.warehouseLocations[0],
        });
      }
    }
  }
  setSelectedStartingLocation(e: DropdownChangeParams) {
    const location = e.value as Location;
    this.setState({selectedStartingWarehouseLocation: location});
  }

  setSelectedFinalLocationOption(value: string) {
    this.setState({
      selectedFinalLocationOption: value,
      selectedFreightOrders: [],
    });

    if (value === finalDestinationsOption) {
      this.changeAllOrdersToFinalLocation();
    } else if (value === twoWarehouseOption) {
      const location = this.state.selectedFinalWarehouseLocation;
      if (location) {
        this.changeAllOrdersToFinalWarehouseLocation(location);
      }
    } else if (value === toHandoverLocationOption) {
      this.changeAllOrdersToHandoverLocation();
    }
  }

  setSelectedFinalLocation(e: DropdownChangeParams) {
    const value = e.value as Location;
    this.setState({selectedFinalWarehouseLocation: value});
    this.changeAllOrdersToFinalWarehouseLocation(value);
  }

  setDefaultSelectedRun() {
    if (this.state.createdRunId) {
      const run = this.state.runs.find(r => r.id === this.state.createdRunId);
      this.setState({selectedRun: run});
    } else {
      const value = this.state.selectedRun ?? this.state.runs[0];
      this.setState({selectedRun: value});
    }
  }

  changeAllOrdersToFinalLocation() {
    const destinationIdMap: MapOf<number> = {};
    const orders = this.props.freightOrders ?? [];

    orders.forEach(order => {
      destinationIdMap[order.id ?? ''] = order.final_destination_id ?? 0;
    });

    this.setState({ordersDestinationLocationsIdMap: destinationIdMap});
  }

  changeAllOrdersToHandoverLocation() {
    const destinationIdMap: MapOf<number> = {};
    const orders = this.props.freightOrders ?? [];
    const state = localStorage.getItem('current state')!;

    orders.forEach(order => {
      if (order.route) {
        destinationIdMap[order.id!] = order.route[state].handover_location_id ?? 0;
      }
    });

    this.setState({ordersDestinationLocationsIdMap: destinationIdMap});
  }

  changeAllOrdersToFinalWarehouseLocation(location: Location) {
    const value = this.state.ordersDestinationLocationsIdMap;
    const orders = this.props.freightOrders ?? [];

    orders.forEach(order => {
      value[order.id ?? ''] = location.id ?? 0;
    });

    const newValue = {...value};
    this.setState({ordersDestinationLocationsIdMap: newValue});
  }

  changeAllOrdersDestinationLocation(location: Location) {
    const selectedLocation = location;
    const value = this.state.ordersDestinationLocationsIdMap;
    const orders = this.state.selectedFreightOrders ?? [];

    if (selectedLocation) {
      if (selectedLocation.id === 0) {
        orders.forEach(order => {
          value[order.id ?? ''] = order.final_destination_id ?? 0;
        });
      } else {
        orders.forEach(order => {
          value[order.id ?? ''] = selectedLocation.id ?? 0;
        });
      }
    }

    const newValue = {...value};
    this.setState({
      ordersDestinationLocationsIdMap: newValue,
      selectedFreightOrders: [],
    });
  }

  changeOrdersDestinationLocation(locationId: number, order: string) {
    const value = this.state.ordersDestinationLocationsIdMap;
    value[order] = locationId;

    const newValue = {...value};
    this.setState({ordersDestinationLocationsIdMap: newValue});
  }

  async loadRuns() {
    this.setState({
      loading: true,
    });
    const sortBy: string[] = [];
    const filters: string[] = [];

    const stageFilterValue: RunStage = 'Done';
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: stageFilterValue,
        condition: '<>',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    return this.runsService?.getRuns(params).then(data => {
      const dataRecords = (data?.records as Run[]) ?? [];
      const stopsIds: number[] = [];
      dataRecords.forEach(run => {
        run.stops
          ?.filter(s => s !== null)
          .map(s => {
            stopsIds.push(s.id ?? 0);
          });
      });

      this.loadStops(stopsIds);

      this.setState({
        runs: dataRecords,
        loading: false,
      });

      this.setDefaultSelectedRun();
    });
  }

  async loadLocations() {
    const sortBy: string[] = [];

    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
    };

    return this.locationsService?.getLocations(params).then(data => {
      const dataRecords = (data?.records as Location[]) ?? [];
      const allLocations: Location[] = dataRecords.map(location => {
        //todo temporary fix -> this should be deleted after registration of parser for Number data type on backend
        location.id = +location.id!;
        return location;
      });

      const warehouseLocations = allLocations.filter(l => l.type === 'warehouse' || l.type === 'factory');

      const finalDestinationLocation = this.finalDestinationTemplate();
      const locationsWithFinalDestination = [finalDestinationLocation];
      locationsWithFinalDestination.push(...allLocations);

      this.setState({
        locations: locationsWithFinalDestination,
        warehouseLocations: warehouseLocations,
        selectedStartingWarehouseLocation: undefined,
        selectedFinalWarehouseLocation: warehouseLocations[0],
      });
    });
  }

  async loadStops(ids: number[]) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.stopsService?.getStops(params).then(data => {
      const dataRecords = (data?.records as Stop[]) ?? [];

      this.setState({
        stops: dataRecords,
      });
    });
  }

  prepareOrdersToStops() {
    const freightOrders: FreightOrder[] = this.props.freightOrders;
    const {saveProcess, selectedRun} = this.state;
    const startingWarehouseLocation = this.state.selectedStartingWarehouseLocation;
    const destinationLocationsIdMap = this.state.ordersDestinationLocationsIdMap;

    const updatedOrders: FreightOrder[] = [];
    freightOrders.forEach(order => {
      if (saveProcess! !== 'continue' || !this.ordersTasksForRun(selectedRun!).includes(order)) {
        const fo = {...order};
        if (startingWarehouseLocation) {
          fo.current_location_id = startingWarehouseLocation.id;
        } else {
          const state = localStorage.getItem('current state')!;
          const stage = order.route![state].stage;
          if (stage === 'In Container') {
            fo.current_location_id = order.container?.unload_at_id;
          }
        }
        const orderDestinationLocationId = destinationLocationsIdMap[order.id ?? ''];
        fo.destination_id = !orderDestinationLocationId ? order.final_destination_id : orderDestinationLocationId;

        updatedOrders.push(fo);
      }
    });

    return updatedOrders;
  }

  async removeTasksForOrders(orders: FreightOrder[]) {
    const taskIds: number[] = [];
    const orderIds: string[] = [];
    orders.forEach(order => {
      order.id && orderIds.push(order.id);
    });
    const {selectedRun} = this.state;

    if (selectedRun) {
      selectedRun.tasks?.forEach(task => {
        if (task.freight_order_id && task.id && orderIds.includes(task.freight_order_id)) {
          taskIds.push(task.id);
        }
      });
    }

    const promises: Promise<void>[] = [];

    taskIds.forEach(id => {
      promises.push(this.tasksService?.deleteTask(id.toString()) as Promise<void>);
    });

    Promise.all(promises)
      .then(() => {})
      .catch(() => {
        this.twoToast?.showError('Sorry, tasks removing failed.');
        this.setState({loading: false});
      });
  }

  async updateRun() {
    this.setState({loading: true});
    const run = this.state.selectedRun;
    if (!run) {
      this.twoToast?.showError('Sorry, Orders assign failed, please try again.');
      this.setState({loading: false});
      return;
    }
    const {saveProcess} = this.state;

    if (saveProcess === 'replan') {
      const ordersToDeleteTasks = this.ordersTasksForRun(run);
      this.removeTasksForOrders(ordersToDeleteTasks);
    }

    const updatedOrders = this.prepareOrdersToStops();

    //create stops map and set last line up number
    const stopsMap: MapOf<Stop> = {}; // location_id => Stop
    let lastLineUp = 0;
    if (run.stops) {
      for (const stop of run.stops) {
        if (stop) {
          stopsMap[stop.location_id] = stop;
          lastLineUp < stop.line_up && (lastLineUp = stop.line_up);
        }
      }
    }

    //now we will create new tasks in stops
    if (updatedOrders.length > 0) {
      for (const order of updatedOrders) {
        if (!order.current_location_id || !order.destination_id) {
          this.setState({loading: false});
          this.twoToast?.showError('Sorry, Orders assign failed, please try again.');
          return;
        }
        //task for pickup stop
        let pickupStop = stopsMap[order.current_location_id];
        if (!pickupStop) {
          //create new stop
          pickupStop = this.createStopEntity(++lastLineUp, run.id!, order.current_location_id);
          stopsMap[order.current_location_id] = pickupStop;
        }
        // add new task to the pickup stop
        let pickupTaskLastLineUp = 0;
        if (pickupStop.tasks) {
          //this stop has some new task(s)
          pickupTaskLastLineUp = pickupStop.tasks.reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
        } else if (pickupStop.id) {
          //check already saved tasks
          pickupTaskLastLineUp =
            run.tasks?.filter(task => task.stop_id === pickupStop.id).reduce((a, b) => (a.line_up > b.line_up ? a : b))
              ?.line_up ?? 0;
        }

        const newPickupTask = this.createTaskEntity(++pickupTaskLastLineUp, pickupStop, order, 'pickup');
        if (!pickupStop.tasks) {
          pickupStop.tasks = [];
        }
        pickupStop.tasks.push(newPickupTask);

        //task for destination stop
        let destinationStop = stopsMap[order.destination_id];
        if (!destinationStop) {
          //create new stop
          destinationStop = this.createStopEntity(++lastLineUp, run.id!, order.destination_id);
          stopsMap[order.destination_id] = destinationStop;
        }
        // add new task to the destination stop
        let destinationTaskLastLineUp = 0;
        if (destinationStop.tasks) {
          //this stop has some new task(s)
          destinationTaskLastLineUp =
            destinationStop.tasks.reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
        } else if (destinationStop.id) {
          //check already saved tasks

          const filteredTasks = run.tasks?.filter(task => task.stop_id === destinationStop.id);
          if (filteredTasks) {
            destinationTaskLastLineUp = filteredTasks.reduce((a, b) => (a.line_up > b.line_up ? a : b))?.line_up ?? 0;
          }
        }

        const newTask = this.createTaskEntity(++destinationTaskLastLineUp, destinationStop, order, 'drop');
        if (!destinationStop.tasks) {
          destinationStop.tasks = [];
        }
        destinationStop.tasks.push(newTask);
      }

      const updatedRun: RunPatch = {
        stops: Object.values(stopsMap),
      };
      this.runsService
        ?.updateRun(run.id!.toString(), updatedRun)
        .then(() => {
          if (this.props.removeRunId) {
            this.removeOrdersFromOriginalRun(updatedOrders, this.props.removeRunId);
          }
          this.twoToast?.showSuccess(`Orders assigned to run ${this.state.selectedRun?.name ?? ''} successfully.`);
          this.setState({loading: false});
          MessageService.sendMessage(messages.runOrderCreate);
          this.hideDialog();
        })
        .catch(() => {
          this.twoToast?.showError('Sorry, Orders assign failed, please try again.');
          this.setState({loading: false});
        });
    } else {
      this.setState({loading: false});
      MessageService.sendMessage(messages.runOrderCreate);
      this.hideDialog();
    }
  }

  createStopEntity(lineUp: number, runId: number, locationId: number) {
    const stop: Stop = {
      stage: 'Planned',
      line_up: lineUp,
      run_id: runId,
      location_id: locationId,
    };
    return stop;
  }

  createTaskEntity(lineUp: number, stop: Stop | null, order: FreightOrder, type: TaskType) {
    if (type === 'drop') {
      if (order.final_destination_id === stop?.location_id) {
        type = 'final-drop';
      }
    }
    const task: Task = {
      line_up: lineUp,
      stop_id: stop?.id ?? 0,
      freight_order_id: order.id ?? '',
      type: type,
    };
    return task;
  }

  createTle(freightOrder: FreightOrder, tle: TimeLineEvent) {
    return this.tlesService?.createTle(tle).then(data => {
      if (data) {
        const updatedOrder: OrderPatch = {
          last_event_id: data.id,
        };
        this.ordersService?.updateOrder(freightOrder.id ?? '', updatedOrder);
      }
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  removeOrdersFromOriginalRun(selectedFreightOrders: FreightOrder[], runId: string) {
    const selectedFreightOrdersIds = selectedFreightOrders.map(fo => {
      return fo.id;
    });

    const runStops = this.state.stops.filter(s => s !== null && s.run_id.toString() === runId) ?? [];
    const tasks: Task[] = [];
    runStops.forEach(stop => {
      const stopTasks =
        stop.tasks?.filter(t => t !== null && selectedFreightOrdersIds.includes(t.freight_order_id)) ?? [];
      tasks.push(...stopTasks);
    });

    this.deleteStopsAndTasks(tasks, runStops, selectedFreightOrders, runId);
  }

  async deleteStopsAndTasks(tasks: Task[], allStops: Stop[], freightOrders: FreightOrder[], runId: string) {
    const tlePromises: Promise<void>[] = [];
    const stops: Stop[] = [];

    const taskIds = tasks.map(t => t.id);
    allStops.map(stop => {
      const stopTasks = stop.tasks?.filter(t => t !== null && !taskIds.includes(t.id)) ?? [];
      if (stopTasks.length === 0) {
        stops.push(stop);
      }
    });

    const deletePromise = this.deleteTasks(tasks).then(() => {
      return this.deleteStops(stops);
    });

    freightOrders.forEach(order => {
      const tle = this.createDeassignedTleEntity(order, runId.toString());
      const promise = this.createTle(order, tle);

      if (promise) {
        tlePromises.push(promise);
      }
    });

    deletePromise
      .then(() => {
        Promise.all(tlePromises);
        this.twoToast?.showSuccess('Orders removed from run successfully.');
      })
      .catch(() => {
        this.twoToast?.showError('Sorry, Orders delete from run failed, please try again.');
      });
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.deleteTask(task.id.toString());
        }
      })
    );
  }

  async deleteStops(stops: Stop[]) {
    Promise.all(
      stops.map(async (stop: Stop) => {
        if (stop.id) {
          return this.stopsService?.deleteStop(stop.id.toString());
        }
      })
    );
  }

  createDeassignedTleEntity(freightOrder: FreightOrder, runId: string) {
    const tleLink: TleLink = {link_type: 'run', linked_id: runId};
    const content: TleContentUnassigned = {
      message: 'Order was deassigned from a run',
      links: [tleLink],
    };

    const tle: TimeLineEvent = {
      event_type: 'unassigned',
      entity_type: 'order',
      recorded_by: this.getCurrentUserId(),
      entity_id: freightOrder.id ?? '',
      content: content,
      recorded_at: new Date(),
    };
    return tle;
  }

  initMenuItems(selectedOrders: FreightOrder[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    if (selectedOrders.length > 0) {
      const runMenu: AppMenuItem = {
        label: 'Change Destination',
        faIcon: ['fas', 'warehouse'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showSetLocationDialog(selectedOrders);
        },
      };

      menuItems.push(runMenu);
    }

    return menuItems;
  }

  hideDialog() {
    this.setState({
      createdRunId: undefined,
      selectedRun: undefined,
      selectedFreightOrders: [],
      selectedStartingLocationOption: currentStartingLocationsOption,
      selectedFinalLocationOption: finalDestinationsOption,
      selectedFinalWarehouseLocation: undefined,
    });
    this.props.onHide();
  }

  hideAddRunDialog(runId: number | undefined) {
    this.setState({showAddRunDialog: false, createdRunId: runId});
  }

  hideSetLocationDialog() {
    this.setState({showSetLocationDialog: false});
  }

  showSetLocationDialog(selectedOrders: FreightOrder[]) {
    const locations = this.state.locations;
    const ids = selectedOrders.map(o => {
      return o.id ?? '';
    });
    const freightOrders = this.props.freightOrders?.filter(fo => ids.includes(fo.id ?? ''));

    let ownerId: string | undefined = '';

    const selectedOrdersOwners = this.props.freightOrders.map(o => {
      return o.order?.owner;
    });

    const allEqual = selectedOrdersOwners.every(v => v === selectedOrdersOwners[0]);

    if (allEqual) {
      ownerId = selectedOrdersOwners[0];
    }

    const locationsForDestination = locations.filter(
      l =>
        l.id === 0 ||
        l.type === 'warehouse' ||
        l.type === 'factory' ||
        (l.type === 'dealership' && l.dealership_id?.toString() === ownerId)
    );

    this.setState({
      showSetLocationDialog: true,
      selectedFreightOrders: freightOrders,
      locationsForDestination: locationsForDestination,
    });
  }

  selectedRunTemplate = (option: Run) => {
    if (option) {
      return this.runTemplate(option);
    }

    return <span>{}</span>;
  };

  runTemplate = (option: Run) => {
    const vehicle = option.vehicle?.name;
    const vehicleSchedule = option.schedule_entry?.start_at;
    const vehicleScheduleDate = vehicleSchedule
      ? DateTime.fromISO(vehicleSchedule.toString()).toFormat(formats.date)
      : undefined;
    const stops = option.stops?.filter(s => s !== null) ?? [];
    return (
      <div>{`${option.name} | ${stops.length} stops${vehicle ? ' | ' + vehicle : ''}${
        vehicleScheduleDate ? ' | ' + vehicleScheduleDate : ''
      }`}</div>
    );
  };

  finalDestinationTemplate = () => {
    const finalDestinationLocation: Location = {
      name: 'FINAL DESTINATION',
      id: 0,
      type: 'other',
      state_id: '',
      address: {
        lat: 0,
        long: 0,
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        state_short: '',
        country: '',
        phoneNumber: '',
      },
    };

    return finalDestinationLocation;
  };

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label={this.props.removeRunId ? 'Re-Assign' : 'Assign'}
          className={'p-mr-2'}
          onClick={() => {
            this.updateRun();
          }}
          disabled={this.state.loading}
          autoFocus
        />
      </div>
    );
  }

  locationOptionTemplate(location: Location | undefined) {
    const state = localStorage.getItem('current state') ?? '';
    if (location) {
      if (location.state_id === state) {
        return <span>{location.name}</span>;
      }
      return <span>{`${location.name} ${location.state_id}`}</span>;
    }
    return <></>;
  }

  render() {
    const {
      locations,
      locationsForDestination,
      warehouseLocations,
      selectedStartingLocationOption,
      selectedFinalLocationOption,
      ordersDestinationLocationsIdMap,
    } = this.state;
    const {removeRunId, isAdditionalRunAssignment, freightOrders} = this.props;
    const locationsForOder = locations.filter(l => l.id !== 0);

    let dialogTitle = 'Assign Orders to a Run';
    let runLabel = 'I want the run';
    if (removeRunId) {
      dialogTitle = 'Re-Assign Orders to a Different Run';
      runLabel = 'Move the order(s) to run';
    } else if (isAdditionalRunAssignment) {
      dialogTitle = 'Assign Orders to Additional Run';
      runLabel = 'Assign order(s) to additional run';
    }

    const finalLocationOptions = getFinalLocationOptions(freightOrders);

    const dialogBody = (
      <>
        <div className={'assign-orders-detail w-100'} style={{height: 'calc(100% - 84px)'}}>
          <div className="p-d-flex p-mb-2">
            <label className="p-col-2 p-as-center p-text-center">{runLabel}</label>
            <div className="p-d-flex p-col-10 p-lg-4 p-p-0">
              <Dropdown
                className="p-d-flex w-100"
                value={this.state.selectedRun}
                options={this.state.runs}
                onChange={e => this.setSelectedRun(e)}
                optionLabel="name"
                valueTemplate={this.selectedRunTemplate}
                itemTemplate={this.runTemplate}
              />
            </div>
            <div className="p-d-flex p-col-2 p-lg-6 p-py-0">
              <Button
                label="Create New"
                onClick={() => {
                  this.setState({showAddRunDialog: true});
                }}
              />
            </div>
          </div>
          <div className="p-d-flex p-mb-2">
            <label className="p-col-2 p-as-center p-text-center p-mt-2">{'to pick the order(s) below at'}</label>
            <div className="p-d-flex p-col-10 p-lg-4 p-p-0 p-mt-2">
              <Dropdown
                className="p-d-flex w-100"
                value={selectedStartingLocationOption}
                options={startingLocationOptions}
                onChange={e => this.setSelectedStartingLocationOption(e)}
              />
              {selectedStartingLocationOption === twoWarehouseOption && (
                <Dropdown
                  className="p-d-flex w-100 p-ml-2"
                  value={this.state.selectedStartingWarehouseLocation}
                  options={warehouseLocations}
                  optionLabel="name"
                  onChange={e => this.setSelectedStartingLocation(e)}
                  valueTemplate={this.locationOptionTemplate}
                  itemTemplate={this.locationOptionTemplate}
                />
              )}
            </div>
            <label className="p-col-2 p-as-center p-text-center p-mt-2">{'and to deliver them to '}</label>
            <div className="p-d-flex p-col-10 p-lg-4 p-p-0 p-mt-2">
              <Dropdown
                className="p-d-flex w-100"
                value={selectedFinalLocationOption}
                options={finalLocationOptions}
                onChange={e => this.setSelectedFinalLocationOption(e.value)}
              />
              {this.state.selectedFinalLocationOption === twoWarehouseOption && (
                <Dropdown
                  className="p-d-flex w-100 p-ml-2"
                  value={this.state.selectedFinalWarehouseLocation}
                  options={warehouseLocations}
                  optionLabel="name"
                  onChange={e => this.setSelectedFinalLocation(e)}
                  valueTemplate={this.locationOptionTemplate}
                  itemTemplate={this.locationOptionTemplate}
                />
              )}
            </div>
          </div>

          <div className="p-col-12 p-p-0" style={{height: '100%'}}>
            <OrderListComponent
              key="run-orders"
              id="orders"
              heightToScroll={'max'}
              hideMenu={selectedFinalLocationOption !== differentDestinationsOption}
              isAll={true}
              freightOrderIds={this.props.freightOrders.map(o => o.id ?? '')}
              freightOrderExcludedStages={['On Board', 'Delivered']}
              ordersDestinationLocations={ordersDestinationLocationsIdMap}
              changeDestinationLocation={(locationId, orderId) =>
                this.changeOrdersDestinationLocation(locationId, orderId)
              }
              locations={locationsForOder}
              customMenuItems={orders => this.initMenuItems(orders)}
              selectedOrders={this.state.selectedFreightOrders}
              setSelectedOrders={(groupId, orders) => this.setSelectedOrders(orders)}
              groupId={'orders'}
              runDestinationLocationsDisabled={selectedFinalLocationOption !== differentDestinationsOption}
            />
          </div>
        </div>
      </>
    );
    return (
      <>
        <TwoDialog
          headerTitle={dialogTitle}
          showDialog={this.props.showDialog}
          width={90}
          onShow={this.loadData}
          onHide={this.hideDialog}
          onSave={this.save}
          loading={this.state.loading}
          saveButtonTitle={this.props.removeRunId ? 'Re-Assign' : 'Assign'}
        >
          {dialogBody}
        </TwoDialog>

        <AddRunDialog showDialog={this.state.showAddRunDialog} onHide={runId => this.hideAddRunDialog(runId)} />
        {this.state.selectedRun && this.state.showCheckOrdersDialog && (
          <OrdersTasksOnRunDialog
            showDialog={this.state.showCheckOrdersDialog}
            onHide={() => {
              this.setState({
                showCheckOrdersDialog: false,
              });
            }}
            orders={this.ordersTasksForRun(this.state.selectedRun)}
            run={this.state.selectedRun}
            onContinue={async () => {
              await this.setState({
                saveProcess: 'continue',
                showCheckOrdersDialog: false,
              });
              this.updateRun();
            }}
            onRePlan={async () => {
              await this.setState({
                saveProcess: 'replan',
                showCheckOrdersDialog: false,
              });
              this.updateRun();
            }}
          />
        )}
        <SetLocationDialog
          showDialog={this.state.showSetLocationDialog}
          onHide={() => this.hideSetLocationDialog()}
          setLocationsToOrders={location => this.changeAllOrdersDestinationLocation(location)}
          locations={locationsForDestination}
        />
      </>
    );
  }
}
export default AssignOrdersToRunDialog;
