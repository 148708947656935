import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  MessageService,
  TwoMessage,
} from 'two-app-ui';
import {faCalendarAlt, faPencil, faList, faCamera} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {Location, QueryParameter, TimeLineEvent} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import LocationOrders from './LocationOrders';
import LocationDetail from './LocationDetail';
import LocationsService from '../../services/LocationsService';
import {EditLocationDialog} from './EditLocationDialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import TlesService from '../../services/TlesService';
import ProofList from '../Proofs/ProofList';

library.add(faCalendarAlt, faPencil, faList);

interface RouteProps {
  id: string;
}

interface State {
  location: Location | undefined;
  loadingLocation: boolean;
  loadingSecondaryView: boolean;
  items: TwoTimelineItem[];
  showLocationForm: boolean;
  events: TimeLineEvent[];
}

class LocationComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;
  tlesService: TlesService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingLocation: false,
      loadingSecondaryView: false,
      location: undefined,
      items: [],
      showLocationForm: false,
      events: [],
    };
    this.hideEditDialog = this.hideEditDialog.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.toastService = this.context.toastService;
    this.tlesService = this.context.tlesService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.props.history.push('/locations');
      }
    });

    const id = this.props.match.params.id;
    this.loadLocation(id);
    this.loadEvents(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadLocation(id: string) {
    this.setState({loadingLocation: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      showAll: true,
    };
    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const location = (data.records as Location[])[0];
        this.setState({
          location: location,
          loadingLocation: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Location load failed, please try again.');
        this.setState({
          loadingLocation: false,
        });
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'location',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  setLoadingSecondaryView(value: boolean) {
    this.setState({loadingSecondaryView: value});
  }

  showEditDialog(location: Location) {
    this.setState({location: location, showLocationForm: true});
  }

  hideEditDialog() {
    this.setState({showLocationForm: false});
    const id = this.props.match.params.id;
    this.loadLocation(id);
    this.loadEvents(id);
  }

  getActions(location: Location): TwoAction[] {
    const editDetailAction = {
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditDialog(location);
      },
    };

    return [editDetailAction];
  }

  render() {
    const {location} = this.state;
    return location ? (
      <>
        <TwoEntityComponent title={location.name} actions={this.getActions(location)} showDetail={false}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingLocation ? (
              <LocationDetail location={location} history={this.props.history} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>

          <TwoEntityPanel label="Orders" icon={faList} tooltip="Orders">
            {!this.state.loadingSecondaryView ? (
              <LocationOrders location={location} history={this.props.history} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Proofs" icon={faCamera} tooltip="Proofs">
            {!this.state.loadingSecondaryView ? <ProofList location={location}></ProofList> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? (
              <TwoTimeline key={this.state.location?.id} items={this.state.items} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <EditLocationDialog
          toast={this.toast}
          location={location}
          showDialog={this.state.showLocationForm}
          onHide={this.hideEditDialog}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(LocationComponent);
