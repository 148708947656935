import React from 'react';
import {faMinus, faPencil, faPersonCarryBox, faTruckLoading, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {MenuItemOptions} from 'primereact/menuitem';
import {AppMenuItem, AppMenuItemTemplate, TwoAction} from 'two-app-ui';
import {FreightOrder} from 'two-core';
import {faTruckMoving, faWarehouse} from '@fortawesome/free-solid-svg-icons';

/**
 * Get the age of a freight order in days
 * @param freightOrder
 */
export const getFreightOrderAgeInDays = (freightOrder?: FreightOrder): number => {
  if (!freightOrder) {
    return 0;
  }

  const deliveryDate = freightOrder?.delivered_at ? new Date(freightOrder?.delivered_at.toString()) : undefined;
  const handedOver = freightOrder?.handed_over ? new Date(freightOrder?.handed_over.toString()) : undefined;
  if (!handedOver) {
    return 0;
  }
  return Math.round(((deliveryDate?.getTime() ?? Date.now()) - handedOver?.getTime()) / (1000 * 60 * 60 * 24));
};

export interface FreightOrderMenuFunctions {
  onAssignToRun?: () => void;
  onRemoveFromRun?: () => void;
  onDeliver?: () => void;
  onPickUpByThirdParty?: () => void;
  onChangeCurrentLocation?: () => void;
  onUnload?: () => void;
  onUnDeliver?: () => void;
  onEdit?: () => void;
}
interface ItemData {
  label?: string;
  icon?: IconDefinition;
  action?: () => void;
  separator?: boolean;
  main?: boolean;
}

/**
 * Get button menu items for factory order
 * @param selectedOrder
 * @param functions
 */
export function getFreightOrderButtonMenuItems(
  selectedOrder: FreightOrder,
  functions: FreightOrderMenuFunctions,
  state = ''
): TwoAction[] {
  const items = getFreightOrdersItems([selectedOrder], functions, false, state);
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      icon: item.icon,
      action: item.action,
      main: item.main,
    };
  });
}

/**
 * Get table menu items for factory orders
 * @param selectedOrders
 * @param functions
 * @param showContainerMenu
 * @param state
 */
export function getFreightOrdersMenuItems(
  selectedOrders: FreightOrder[],
  functions: FreightOrderMenuFunctions,
  showContainerMenu = false,
  state = ''
): AppMenuItem[] {
  const items = getFreightOrdersItems(selectedOrders, functions, showContainerMenu, state);
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      faIcon: item.icon,
      command: item.action,
      template: (item: AppMenuItem, options: MenuItemOptions) => <AppMenuItemTemplate item={item} options={options} />,
    };
  });
}
function getFreightOrdersItems(
  selectedItems: FreightOrder[],
  functions: FreightOrderMenuFunctions,
  showContainerMenu = false,
  state = ''
): ItemData[] {
  const items: ItemData[] = [];
  if (!selectedItems.length) {
    return items;
  }
  if (selectedItems.every(order => order.route?.[state]?.stage !== 'Delivered')) {
    if (selectedItems.length === 1 && functions.onEdit) {
      items.push({
        label: 'Edit',
        icon: faPencil,
        action: functions.onEdit,
        main: true,
      });
    }
    if (selectedItems.every(order => order.tasks?.length || order.runs?.length)) {
      items.push({
        label: 'Remove From Run(s)',
        icon: faMinus,
        action: functions.onRemoveFromRun,
      });
    }
  }

  const storageOrderStages = ['Stored', 'Ready 4 Pick Up', 'At Warehouse', 'At Factory'];
  if (
    selectedItems.every(
      order => order.route?.[state]?.stage && storageOrderStages.includes(order.route?.[state]?.stage)
    )
  ) {
    if (functions.onAssignToRun) {
      items.push({
        label: 'Assign to Run',
        icon: faTruckMoving,
        action: functions.onAssignToRun,
      });
    }
    if (functions.onDeliver) {
      items.push({
        label: 'Deliver',
        icon: faTruckMoving,
        action: functions.onDeliver,
      });
      items.push({
        label: 'Picked Up by Customer',
        icon: faPersonCarryBox,
        action: functions.onDeliver,
      });
    }
    if (functions.onPickUpByThirdParty) {
      items.push({
        label: 'Pick Up by Third Party',
        icon: faTruckMoving,
        action: functions.onPickUpByThirdParty,
      });
    }
  }

  if (selectedItems.every(order => order.route?.[state]?.stage === 'With 3rd Party')) {
    if (functions.onDeliver) {
      items.push({
        label: 'Delivered',
        icon: faPersonCarryBox,
        action: functions.onDeliver,
      });
    }
  }

  if (functions.onChangeCurrentLocation) {
    items.push({
      label: 'Change Current Location',
      icon: faWarehouse,
      action: functions.onChangeCurrentLocation,
    });
  }

  if (showContainerMenu && selectedItems.every(order => order.route?.[state]?.stage === 'In Container')) {
    const hasSameContainer = selectedItems.every((val, _, arr) => val.container_id === arr[0].container_id);
    if (hasSameContainer && functions.onUnload) {
      items.push({
        label: 'Unloaded',
        icon: faTruckLoading,
        action: functions.onUnload,
      });
    }
  }

  if (selectedItems.every(order => order.route?.[state]?.stage === 'Delivered')) {
    const isNotInDealerLocation = selectedItems.every(freightOrder => freightOrder.location?.type !== 'dealership');
    if (isNotInDealerLocation && functions.onUnDeliver) {
      items.push({
        label: 'Undelivered',
        icon: faTruckMoving,
        action: functions.onUnDeliver,
      });
    }
  }

  //remove last item if it is separator
  if (items.length && items[items.length - 1].separator) {
    items.pop();
  }
  return items;
}
