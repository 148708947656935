import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import React, {Component} from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  TwoMessage,
  TwoToast,
} from 'two-app-ui';
import {QueryParameter, Vehicle} from 'two-core';
import VehiclesService from '../../services/VehiclesService';
import EditVehicleDialog from './EditVehicleDialog';
import {Subscription} from 'rxjs';
import {faPencil, faPlus, faRedo, faToggleOff, faToggleOn, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';

interface State {
  loading: boolean;
  showEditDialog?: boolean;
  vehicles: Vehicle[];
  totalVehicles: number;
  selectedVehicle?: Vehicle;
  filter?: {
    showDeleted?: boolean;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
}

export default class VehicleList extends Component<{}, State> {
  static contextType = AppContext;
  subscription?: Subscription;
  vehiclesService?: VehiclesService;
  twoToast?: TwoToast;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      vehicles: [],
      totalVehicles: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: {field: 'name', order: 1},
    };
    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.headerMenuItems = this.headerMenuItems.bind(this);
    this.rowMenuItems = this.rowMenuItems.bind(this);
    this.onHideEditDialog = this.onHideEditDialog.bind(this);
    this.nameBody = this.nameBody.bind(this);
  }

  componentDidMount() {
    this.vehiclesService = this.context.vehiclesService;
    this.twoToast = this.context.twoToast;
    this.subscription = MessageService.getMessage().subscribe(message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  async loadData() {
    const {filter, sortBy, pagination} = this.state;
    this.setState({loading: true});
    const {vehicles, totalVehicles} = await this.loadVehicles(filter, sortBy, pagination.pageSize, pagination.offset);
    this.setState({loading: false, vehicles, totalVehicles: totalVehicles ?? 0});
  }

  async loadVehicles(
    filter?: {showDeleted?: boolean},
    sortBy?: {
      field: string;
      order: DataTableSortOrderType;
    },
    pageSize?: number,
    offset?: number
  ) {
    try {
      const filters: string[] = [];
      if (filter?.showDeleted) {
        filters.push(
          JSON.stringify({
            condition: 'includeDeleted',
            value: true,
          })
        );
      }

      let orderBys = undefined;
      if (sortBy) {
        orderBys = [JSON.stringify({field: sortBy.field, direction: sortBy.order === 1 ? 'asc' : 'desc'})];
      }
      const queryParams: QueryParameter = {
        page_size: pageSize,
        offset,
        orderBys,
        filters,
      };
      const response = await this.vehiclesService?.getVehicles(queryParams);
      const vehicles = (response?.records ?? []) as Vehicle[];
      const totalVehicles = response?.total_records ?? 0;
      return {vehicles, totalVehicles};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading vehicles');
      return {vehicles: [], totalVehicles: 0};
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(name: string, value: string) {
    await this.setState({filter: {...this.state.filter, [name]: value}});
    this.loadData();
  }

  onHideEditDialog() {
    this.setState({showEditDialog: false, selectedVehicle: undefined});
    this.loadData();
  }
  headerMenuItems(): MenuItem[] {
    const {filter} = this.state;
    const menuItems: MenuItem[] = [];
    menuItems.push({
      label: 'Add',
      faIcon: faPlus,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.setState({showEditDialog: true}),
    });
    menuItems.push({
      label: filter?.showDeleted ? 'Hide Archived' : 'Show Archived',
      faIcon: filter?.showDeleted ? faToggleOff : faToggleOn,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () =>
        this.setState(
          () => ({filter: {...filter, showDeleted: !filter?.showDeleted}}),
          () => this.loadData()
        ),
    });
    return menuItems;
  }

  rowMenuItems(vehicle: Vehicle): MenuItem[] {
    const menuItems: MenuItem[] = [];
    menuItems.push({
      label: 'Rename',
      faIcon: faPencil,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({selectedVehicle: vehicle, showEditDialog: true});
      },
    });
    if (vehicle.deleted) {
      menuItems.push({
        label: 'Restore',
        faIcon: faRedo,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.vehiclesService?.updateVehicle(vehicle.id!, {deleted: false}).then(() => this.loadData());
        },
      });
    } else {
      menuItems.push({
        label: 'Archive',
        faIcon: faTrashAlt,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.vehiclesService?.updateVehicle(vehicle.id!, {deleted: true}).then(() => this.loadData());
        },
      });
    }
    return menuItems;
  }

  nameBody(rowData: Vehicle) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.rowMenuItems(rowData)}
        selectedItems={[]}
      >
        {rowData.name}
      </AppColumnMenuBodyTemplate>
    );
  }

  deletedBody(rowData: Vehicle) {
    return rowData.deleted ? 'Yes' : 'No';
  }

  render() {
    const {loading, vehicles, totalVehicles, selectedVehicle, filter, pagination, sortBy, showEditDialog} = this.state;
    return (
      <div className="b2b-integrations-table">
        <TwoDataTable
          loading={loading}
          value={vehicles}
          activeFilters={{}}
          selectedItems={[]}
          rows={pagination.pageSize}
          first={pagination.offset}
          totalRecords={totalVehicles}
          onSort={this.onSort}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={this.onPageChange}
          initMenuItems={this.headerMenuItems}
          hideFilter
          isMenuDynamic
        >
          <Column header="Name" body={this.nameBody} sortField="name" sortable />
          <Column header="Archived" body={this.deletedBody} hidden={!filter?.showDeleted} />
        </TwoDataTable>
        <EditVehicleDialog
          showDialog={showEditDialog ?? false}
          onHide={this.onHideEditDialog}
          vehicle={selectedVehicle}
        />
      </div>
    );
  }
}
