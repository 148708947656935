import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {DropdownChangeParams} from 'primereact/dropdown';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {
  AppContext,
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  MessageService,
  ToastService,
  TwoMessage,
} from 'two-app-ui';
import {QueryParameter, Run, Stop, Task} from 'two-core';
import RunsService from '../../services/RunsService';
import {Subscription} from 'rxjs';
import StopListComponent from '../Stops/StopListComponent';
import config from '../../config/config';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import StopsService from '../../services/StopsService';
import AddOrdersToRunDialog from './AddOrdersToRunDialog';
import {messages} from '../../config/messages';
import TasksService from '../../services/TasksService';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import SchedulesService from '../../services/SchedulesService';
import EditDialog from './EditDialog';
import RemoveOrderFromRunDialog from './RemoveOrdersFromRunDialog';
import ReportsService from '../../services/ReportsService';
import RunFilterBoxComponent from './RunFilterBoxComponent';
import {NavLink} from 'react-router-dom';
import {MultiSelect} from 'primereact/multiselect';
import AddRunDialog from '../Orders/AddRunDialog';
import {Tooltip} from 'primereact/tooltip';

export const stages: string[] = ['Draft', 'Scheduled', 'In Progress', 'Done'];
interface Props {
  unassignedOnly?: boolean; // table show only unassigned runs
  reloadData?: boolean;
  runDetailId?: string; // show only one run in table with this id (it is run detail)
}

interface State {
  loading: boolean;
  items: Run[];
  selectedItems: Run[];
  totalItems: number;
  activeFilters: {};
  filters: {
    name: string;
    vehicle: string;
    date: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    showDone: boolean;
    showDeletedVehicles: boolean;
    stage: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: any[] | {[key: string]: boolean};
  showAddOrderToRunDialog: boolean;
  showRemoveOrdersFromRunDialog: boolean;
  showAddRunDialog: boolean;
  showEditRunDialog: boolean;
  plannedBoxesCount: number;
  plannedRunsCount: number;
  inProgressBoxesCount: number;
  inProgressRunsCount: number;
}

class RunListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  runsService: RunsService | null = null;
  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  schedulesService: SchedulesService | null = null;
  reportsService: ReportsService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: true,
      activeFilters: {},
      filters: {
        name: '',
        vehicle: '',
        date: {
          fromDate: null,
          toDate: null,
        },
        showDone: false,
        showDeletedVehicles: false,
        stage: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: {},
      showAddOrderToRunDialog: false,
      showAddRunDialog: false,
      showEditRunDialog: false,
      showRemoveOrdersFromRunDialog: false,
      plannedBoxesCount: 0,
      plannedRunsCount: 0,
      inProgressBoxesCount: 0,
      inProgressRunsCount: 0,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.stopsBodyTemplate = this.stopsBodyTemplate.bind(this);
    this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.initTopMenuItems = this.initTopMenuItems.bind(this);
    this.showEditRunDialog = this.showEditRunDialog.bind(this);
    this.hideEditRunDialog = this.hideEditRunDialog.bind(this);
  }

  async componentDidMount() {
    this.runsService = this.context.runsService;
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.schedulesService = this.context.schedulesService;
    this.reportsService = this.context.reportsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (
        message === messages.runOrderCreate ||
        message === messages.runCreate ||
        message === messages.runUpdated ||
        message === messages.stopUpdate
      ) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.loadData();
        }
      }
    });
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (this.props.reloadData !== props.reloadData) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    this.loadRunFilterBoxData();
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    if (this.props.unassignedOnly) {
      filters.push(
        JSON.stringify({
          field: 'vehicle.id',
          condition: 'isNull',
        })
      );
    } else {
      if (this.props.runDetailId) {
        filters.push(
          JSON.stringify({
            field: 'id',
            value: this.props.runDetailId,
          })
        );
      }
      if (this.state.filters.vehicle) {
        filters.push(
          JSON.stringify({
            field: 'vehicle.name',
            value: this.state.filters.vehicle,
            condition: 'iLike',
          })
        );
      }
      if (this.state.filters.date.fromDate) {
        const fromDate = this.state.filters.date.fromDate.toISODate();
        filters.push(
          JSON.stringify({
            field: 'schedule_entry.start_at',
            value: fromDate,
            condition: '>=',
          })
        );
      }
      if (this.state.filters.date.toDate) {
        const toDate = this.state.filters.date.toDate.toISODate();
        filters.push(
          JSON.stringify({
            field: 'schedule_entry.start_at',
            value: toDate,
            condition: '<=',
          })
        );
      }
      if (!this.state.filters.showDone) {
        filters.push(
          JSON.stringify({
            field: 'stage',
            value: 'Done',
            condition: '<>',
          })
        );
      }
      if (!this.state.filters.showDeletedVehicles) {
        filters.push(
          JSON.stringify({
            field: 'vehicle.deleted',
            value: 'false',
          })
        );
      }
    }

    filters.push(
      JSON.stringify({
        field: 'state_id',
        value: localStorage.getItem('current state'),
      })
    );

    this.setState({activeFilters: {...filters}});

    const sortByField = this.state.sortBy?.field ?? 'id';

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data?.records as Run[]) ?? [];
        const runs: Run[] = dataRecords.map(run => {
          //remove [null] from tasks
          const tasks = run.tasks?.filter(task => task) ?? [];
          //remove [null] from stops
          const filteredStops = run.stops?.filter(stop => stop) ?? [];
          //add tasks to stops
          const stops: Stop[] = filteredStops.map(stop => {
            const tasksOfStop = tasks.filter(task => task.stop_id === stop.id);
            return {...stop, tasks: tasksOfStop};
          });
          return {...run, tasks: tasks, stops: stops};
        });

        //expand the row if this table is run detail
        const expandedRows = this.props.runDetailId ? {[this.props.runDetailId]: true} : this.state.expandedRows;

        this.handleSelectedItems(runs);
        this.setState({
          items: runs,
          expandedRows: expandedRows,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadRunFilterBoxData() {
    await this.reportsService
      ?.loadReportForRunList()
      .then(data => {
        this.setState({
          plannedBoxesCount: data.plannedBoxesCount,
          plannedRunsCount: data.plannedRunsCount,
          inProgressBoxesCount: data.inProgressBoxesCount,
          inProgressRunsCount: data.inProgressRunsCount,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, report data load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  initTopMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [
      {
        label: 'Add New Run',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddRunDialog();
        },
      },
    ];

    return menuItems;
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    if (selectedItemsCount === 1) {
      const addOrdersMenu: AppMenuItem = {
        label: 'Add Orders',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddOrdersToRunDialog();
        },
      };

      const renameMenuItem: AppMenuItem = {
        label: 'Rename',
        faIcon: ['far', 'pen'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showEditRunDialog();
        },
      };

      const removeOrdersMenuItem: AppMenuItem = {
        label: 'Remove Orders',
        faIcon: ['far', 'minus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showRemoveOrdersFromRunDialog();
        },
      };

      menuItems.push(addOrdersMenu);
      menuItems.push(renameMenuItem);
      menuItems.push(removeOrdersMenuItem);

      if (selectedItems.every(item => this.canBeRunCancelled(item))) {
        const cancelMenuItem: AppMenuItem = {
          label: 'Cancel',
          faIcon: ['far', 'times'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.cancelRuns();
          },
        };

        menuItems.push(cancelMenuItem);
      }
    }

    return menuItems;
  }

  showAddOrdersToRunDialog() {
    this.setState({showAddOrderToRunDialog: true});
  }

  showRemoveOrdersFromRunDialog() {
    this.setState({showRemoveOrdersFromRunDialog: true});
  }

  showAddRunDialog() {
    this.setState({showAddRunDialog: true});
  }

  showEditRunDialog() {
    this.setState({showEditRunDialog: true});
  }

  hideEditRunDialog() {
    this.setState({showEditRunDialog: false});
  }

  /**
   * Run can be canceled if none of its stops have been started.
   * @param run
   */
  canBeRunCancelled(run: Run) {
    return !run.tasks?.some(task => task.executed_on !== null) ?? true;
  }

  async cancelRuns() {
    this.setState({loading: true});
    const runs: Run[] = this.state.selectedItems;
    const promises: Promise<void>[] = [];

    const runsPromises = runs.map((run: Run) => {
      const stops = run.stops ?? [];
      const tasks: Task[] = run.tasks ?? [];

      return this.deleteTasks(tasks).then(() => {
        return this.deleteStops(stops).then(() => {
          return this.deleteScheduleEntry(run).then(() => {
            return this.deleteRun(run);
          });
        });
      });
    });

    promises.push(...runsPromises);

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Runs deleted successfully.');
        this.loadData();
        this.setChangeSelectedItems([]);
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Runs delete failed, please try again.');
        this.setState({loading: false});
      });
  }

  async deleteTasks(tasks: Task[]) {
    Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.deleteTask(task.id.toString());
        }
      })
    );
  }

  async deleteStops(stops: Stop[]) {
    Promise.all(
      stops.map(async (stop: Stop) => {
        if (stop.id) {
          return this.stopsService?.deleteStop(stop.id.toString());
        }
      })
    );
  }

  async deleteRun(run: Run) {
    return this.runsService?.deleteRun(run.id?.toString() ?? '');
  }

  async deleteScheduleEntry(run: Run) {
    const scheduleEntry = run.schedule_entry;
    if (scheduleEntry) {
      return this.schedulesService?.deleteScheduleEntry(scheduleEntry.id?.toString() ?? '');
    }
  }

  handleSelectedItems(allItems: Run[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Run[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Run[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Run) {
    const items = [item];
    await this.setState({selectedItems: items});
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  rowExpansionTemplate = (run: Run) => {
    return <StopListComponent key={'run' + run.id} run={run} heightToScroll={'max'} hidePaging={true} />;
  };

  codeBodyTemplate(rowData: Run) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={this.initMenuItems}
        selectedItems={[]}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'/run/' + rowData.id}>
          <span id={`code-${rowData.id}`} className="drag-el">
            {rowData.name}
          </span>
          <Tooltip target={`#code-${rowData.id}`} showDelay={500} mouseTrack mouseTrackLeft={15}>
            {rowData.name}
          </Tooltip>
        </NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  stopsBodyTemplate(rowData: Run) {
    const stops = rowData.stops ?? [];
    const doneStops: Stop[] = stops.filter(stop => {
      return !stop.tasks?.some(task => task.executed_on === null);
    });

    return <span>{`${doneStops.length}/${stops.length}`}</span>;
  }

  stageBodyTemplate(rowData: Run) {
    return (
      <span className={`stage-badge run-stage-${(rowData?.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}>
        {rowData?.stage}
      </span>
    );
  }

  dateBodyTemplate(rowData: Run) {
    const date = rowData?.schedule_entry?.start_at;
    return <span>{date ? DateTime.fromISO(date.toString()).toFormat(formats.date) : ''}</span>;
  }
  vehicleBody(rowData: Run) {
    return (
      <>
        <span id={`vehicle-${rowData.id}`}>{rowData?.vehicle?.name}</span>
        <Tooltip target={`#vehicle-${rowData.id}`} showDelay={500} mouseTrack mouseTrackLeft={15}>
          {rowData?.vehicle?.name}
        </Tooltip>
      </>
    );
  }

  render() {
    const codeFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const vehicleFilter = (
      <InputText
        name="vehicle"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const dateFilter = (
      <DateColumnFilter name="date" value={this.state.filters.date} onChange={e => this.onFilterChange(e)} />
    );

    const stageSelectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter run-stage-${value.toLowerCase().replaceAll(' ', '-')}`}
          >
            {value}
          </span>
        );
      }
      return <></>;
    };
    const stageItemTemplate = (option: string) => {
      if (option) {
        return <span className={`stage-badge run-stage-${option.toLowerCase().replaceAll(' ', '-')}`}>{option}</span>;
      }
      return <></>;
    };
    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={stageSelectedItemTemplate}
        itemTemplate={stageItemTemplate}
        value={this.state.filters.stage}
        options={stages}
        className="form-filter"
        name="stage"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <div id="run_list_page_container" className="page-container">
        <div id="filter-box" className={'p-d-flex'}>
          <RunFilterBoxComponent
            plannedBoxesCount={this.state.plannedBoxesCount}
            plannedRunsCount={this.state.plannedRunsCount}
            inProgressBoxesCount={this.state.inProgressBoxesCount}
            inProgressRunsCount={this.state.inProgressRunsCount}
            showDoneRuns={this.state.filters.showDone}
            showDeletedVehicles={this.state.filters.showDeletedVehicles}
            handleFilterChange={this.onFilterChange}
          />
        </div>

        <TwoDataTable
          pageSizeIdentifier={'run_list_page_container'}
          sizeIdentifiers={['filter-box']}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          rowExpansionTemplate={this.rowExpansionTemplate}
          dataKey="id"
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          selectionMode={'single'}
          selectedItems={[]}
          initMenuItems={this.initTopMenuItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Run[])}
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Code"
            body={this.codeBodyTemplate}
            field="name"
            filter
            filterElement={codeFilter}
            sortable
            showFilterMenu={false}
            className="col-min-xxl"
          />
          <Column
            header="Stage"
            field="stage"
            filter
            filterElement={stageFilter}
            sortable
            showFilterMenu={false}
            body={this.stageBodyTemplate}
            className="col-m"
          />
          {!this.props.unassignedOnly && (
            <Column
              header="Vehicle"
              field="vehicle.name"
              body={this.vehicleBody}
              filter
              filterElement={vehicleFilter}
              sortable
              showFilterMenu={false}
              className="col-min-l col-max-xxl"
            />
          )}
          {!this.props.unassignedOnly && (
            <Column
              header="Date"
              field="schedule_entry.start_at"
              filter
              filterElement={dateFilter}
              sortable
              body={this.dateBodyTemplate}
              showFilterMenu={false}
              className="col-s"
            />
          )}
          <Column header="Stops" body={this.stopsBodyTemplate} showFilterMenu={false} className="col-s" />
        </TwoDataTable>
        <AddOrdersToRunDialog
          showDialog={this.state.showAddOrderToRunDialog}
          onHide={() => this.setState({showAddOrderToRunDialog: false})}
          run={this.state.selectedItems[0]}
          toast={this.toast}
        />
        {this.state.selectedItems.length === 1 && this.state.selectedItems[0]?.id && (
          <>
            <EditDialog
              showDialog={this.state.showEditRunDialog}
              onHide={this.hideEditRunDialog}
              toast={this.toast}
              runId={this.state.selectedItems[0]?.id ?? 0}
            />
            <RemoveOrderFromRunDialog
              showDialog={this.state.showRemoveOrdersFromRunDialog}
              onHide={() => this.setState({showRemoveOrdersFromRunDialog: false})}
              toast={this.toast}
              run={this.state.selectedItems[0]}
              stops={this.state.selectedItems[0].stops ?? []}
            />
          </>
        )}
        <AddRunDialog
          showDialog={this.state.showAddRunDialog}
          onHide={() => this.setState({showAddRunDialog: false})}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default RunListComponent;
