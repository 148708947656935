import {LocationType} from 'two-core';

export const locationTypeOptions: {label: string; value: LocationType}[] = [
  {value: 'factory', label: 'Factory'},
  {value: 'warehouse', label: 'Warehouse'},
  {value: 'dealership', label: 'Dealership'},
  {value: 'end-customer', label: 'End Customer'},
  {value: 'service', label: 'Service'},
  {value: 'one-off', label: 'One-Off'},
  {value: 'other', label: 'Other'},
];

export const auStateOptions: string[] = ['ACT', 'NSW', 'QLD', 'VIC', 'SA', 'WA'];
