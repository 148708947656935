import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {MenuItemOptions} from 'primereact/menuitem';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import {
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  TwoMessage,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import {QueryParameter, Location, MapOf} from 'two-core';
import LocationsService from '../../services/LocationsService';
import {Subscription} from 'rxjs';
import config from '../../config/config';
import {messages} from '../../config/messages';
import {EditLocationDialog} from '../Location/EditLocationDialog';
import './Locations.scss';
import {NavLink} from 'react-router-dom';
import GroupOrdersComponent from '../GroupOrders/GroupOrdersComponent';
import LocationFilterboxComponent from '../LocationFilterBox/LocationFilterboxComponent';
import ReportsService from '../../services/ReportsService';
import {getFreightOrderAgeInDays} from '../../utils/FreightOrderUtil';

interface State {
  loading: boolean;
  items: Location[];
  totalItems: number;
  activeFilters: {};
  filters: {
    name: string;
    type: string;
    state_name: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: string[];
  locationOrdersAges: MapOf<number[]>;
  showAddEditDialog: boolean;
  newBoxesCount: number;
  newLocationsCount: number;
  plannedBoxesCount: number;
  plannedLocationsCount: number;
  showAllLocations: boolean;
}

class LocationListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  locationsService: LocationsService | null = null;
  reportsService: ReportsService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: true,
      activeFilters: {},
      filters: {
        name: '',
        type: '',
        state_name: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: [],
      locationOrdersAges: {},
      showAddEditDialog: false,
      newBoxesCount: 0,
      newLocationsCount: 0,
      plannedBoxesCount: 0,
      plannedLocationsCount: 0,
      showAllLocations: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.avgAgeBodyTemplate = this.avgAgeBodyTemplate.bind(this);
    this.oldestBodyTemplate = this.oldestBodyTemplate.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.initAddMenuItem = this.initAddMenuItem.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.hideAddDialog = this.hideAddDialog.bind(this);
    this.onFilterAllLocationsChange = this.onFilterAllLocationsChange.bind(this);
  }

  async componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.reportsService = this.context.reportsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.locationUpdate) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.loadData();
        }
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.loadDataShowAll(this.state.showAllLocations);
  }

  async loadDataShowAll(showAll: boolean) {
    this.setState({loading: true});
    this.loadLocationFilterBoxData();
    const defaultFilters: string[] = [];
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.type && this.state.filters.type.length) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.state_name && this.state.filters.state_name.length) {
      filters.push(
        JSON.stringify({
          field: 'state.id',
          value: this.state.filters.state_name,
          condition: 'in',
        })
      );
    } else {
      const currentState = localStorage.getItem('current state') ?? '';
      defaultFilters.push(
        JSON.stringify({
          field: 'state_id',
          value: currentState,
        })
      );
    }

    this.setState({activeFilters: {...filters}});
    filters.push(...defaultFilters);

    const field = this.state.sortBy?.field;
    let sortByField = field ?? 'type';
    switch (sortByField) {
      case 'assigned':
        sortByField = ':assigned';
        break;
      case 'unassigned':
        sortByField = ':unassigned';
        break;
      case 'handed_over':
        sortByField = ':handed_over';
        break;
    }

    const order = this.state.sortBy?.order;
    const sortOrder =
      order === undefined && field === undefined
        ? 'ASC'
        : field !== 'handed_over'
          ? this.state.sortBy?.order === 1
            ? 'ASC'
            : 'DESC'
          : this.state.sortBy?.order === 1
            ? 'DESC'
            : 'ASC';
    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: sortOrder,
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
      showAll: showAll,
      calculate_values: true,
      showAllTwoLocations: true,
    };

    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const dataRecords = (data?.records as Location[]) ?? [];

        const locationAges: MapOf<number[]> = {};
        dataRecords.map((location: Location) => {
          const ages = this.getAgesOfFreightOrders(location);
          locationAges[location.id ?? ''] = ages;
        });
        this.setState({
          locationOrdersAges: locationAges,
          items: dataRecords,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async loadLocationFilterBoxData() {
    this.reportsService
      ?.loadReportForLocationList()
      .then(data => {
        this.setState({
          newBoxesCount: data.newBoxesCount,
          newLocationsCount: data.newLocationsCount,
          plannedBoxesCount: data.plannedBoxesCount,
          plannedLocationsCount: data.plannedLocationsCount,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  async onFilterAllLocationsChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    this.setState({showAllLocations: value});
    this.loadDataShowAll(value as boolean);
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  initAddMenuItem(): AppMenuItem[] {
    return [
      {
        label: 'Add Location',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddDialog();
        },
      },
    ];
  }

  showAddDialog() {
    this.setState({showAddEditDialog: true});
  }

  hideAddDialog() {
    this.setState({showAddEditDialog: false});
  }

  nameBodyTemplate(location: Location) {
    const state = localStorage.getItem('current state') ?? '';
    let name = location.name;
    if (location.state_id !== state) {
      name += ` ${location.state_id}`;
    }
    return (
      <AppColumnMenuBodyTemplate
        key={location.id}
        rowItemIdentifier={location?.id?.toString() ?? ''}
        isDynamicMenuItems={false}
        selectedItems={[]}
      >
        <NavLink to={'/location/' + location.id}>{name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  avgAgeBodyTemplate(location: Location) {
    const ages = this.state.locationOrdersAges[location.id ?? ''] as number[];
    const average = (ages: number[]) => ages.reduce((a: number, b: number) => a + b, 0) / ages.length;
    const avg = ages.length === 0 ? 0 : average(ages).toFixed(0);
    return <span>{avg}</span>;
  }

  oldestBodyTemplate(location: Location) {
    const ages = this.state.locationOrdersAges[location.id ?? ''];
    const maxAge = ages.length > 0 ? Math.max(...ages) : 0;
    return <span>{maxAge}</span>;
  }

  rowExpansionTemplate = (location: Location) => {
    return <GroupOrdersComponent location={location} heightToScroll={'max'} hideFilter={true} />;
  };

  getAgesOfFreightOrders(location: Location) {
    const freightOrders = location.orders ?? [];
    return freightOrders.map(fo => getFreightOrderAgeInDays(fo));
  }

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={['factory', 'warehouse', 'dealership', 'end-customer', 'service', 'other']}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <div id="location_list_page_container" className="page-container">
        <div id="filter-box" className={'p-d-flex'}>
          <LocationFilterboxComponent
            newBoxes={this.state.newBoxesCount}
            newLocations={this.state.newLocationsCount}
            plannedBoxes={this.state.plannedBoxesCount}
            plannedLocations={this.state.plannedLocationsCount}
            showAllLocations={this.state.showAllLocations}
            handleFilterChange={this.onFilterAllLocationsChange}
          />
        </div>

        <TwoDataTable
          pageSizeIdentifier={'location_list_page_container'}
          sizeIdentifiers={['filter-box']}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          rowExpansionTemplate={this.rowExpansionTemplate}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          initMenuItems={this.initAddMenuItem}
          selection
          selectionMode="multiple"
          columnClassName="no-selection"
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Name"
            field="name"
            filter
            filterElement={nameFilter}
            sortable
            body={this.nameBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column
            header="Type"
            field="type"
            filter
            filterElement={typeFilter}
            sortable
            showFilterMenu={false}
            style={{width: '150px'}}
          />
          <Column header="New" field="unassigned" sortable showFilterMenu={false} style={{width: '50px'}} />
          <Column header="Planned" field="assigned" sortable showFilterMenu={false} style={{width: '50px'}} />
          <Column
            header="Avg age"
            field="handed_over"
            body={this.avgAgeBodyTemplate}
            sortable
            showFilterMenu={false}
            style={{width: '50px'}}
          />
          <Column
            header="Oldest"
            field="handed_over"
            body={this.oldestBodyTemplate}
            sortable
            showFilterMenu={false}
            style={{width: '50px'}}
          />
        </TwoDataTable>
        <EditLocationDialog toast={this.toast} showDialog={this.state.showAddEditDialog} onHide={this.hideAddDialog} />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default LocationListComponent;
