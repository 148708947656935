import React from 'react';
import {Run, Location} from 'two-core';
import {Dropdown} from 'primereact/dropdown';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Skeleton} from 'primereact/skeleton';

interface Props {
  disabled: boolean;
  loadingRuns: boolean;
  runs: Run[];
  locations: Location[];
  selectedRun?: Run;
  selectedLocation?: Location;
  onRunChange: (run: Run) => void;
  onLocationChange: (location: Location) => void;
  onRunNameFilterChange: (name: string) => void;
}

export const SelectionsPanel = ({
  disabled,
  runs,
  locations,
  selectedLocation,
  selectedRun,
  onLocationChange,
  onRunChange,
  onRunNameFilterChange,
  loadingRuns,
}: Props) => {
  const runItemTemplate = (run?: Run, loading?: boolean) => {
    if (loading) {
      return <Skeleton />;
    }
    if (!run) {
      return 'empty';
    }
    return run.name;
  };
  return (
    <div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label className="p-col-12 p-md-2">Run</label>
          <div className="p-col-12 p-md-10">
            <Dropdown
              value={selectedRun}
              options={runs}
              onChange={e => (loadingRuns ? undefined : onRunChange(e.value))}
              disabled={disabled || loadingRuns}
              optionLabel="name"
              filter
              showClear
              onFilter={e => onRunNameFilterChange(e.filter)}
              itemTemplate={run => runItemTemplate(run, loadingRuns)}
              valueTemplate={run => runItemTemplate(run, loadingRuns)}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-12 p-md-2">Location</label>
          <div className="p-col-12 p-md-10">
            <Dropdown
              value={selectedLocation}
              options={locations ?? []}
              onChange={e => onLocationChange(e.value)}
              disabled={disabled}
              optionLabel="name"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
