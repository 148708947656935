import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, MessageService, TwoDialog, TwoToast} from 'two-app-ui';
import {FreightOrder, QueryParameter, Location, FreightOrderPatch} from 'two-core';
import LocationsService from '../../services/LocationsService';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrderListComponent from './OrderListComponent';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  freightOrders: FreightOrder[];
}

interface State {
  loading: boolean;
  locations: Location[];
  selectedLocation: Location | undefined;
}

class ChangeLocationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  locationsService: LocationsService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      locations: [],
      selectedLocation: undefined,
    };

    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setSelectedLocation = this.setSelectedLocation.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const sortBy: string[] = [];
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'type',
        value: ['warehouse', 'factory'],
        condition: 'in',
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
    };

    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const dataRecords = (data.records as Location[]) ?? [];
        this.setState({locations: dataRecords});
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, location records load failed, please try again.');
        console.log(error);
      });
  }

  hideDialog() {
    this.setState({
      loading: false,
      selectedLocation: undefined,
    });
    this.props.onHide();
  }

  setSelectedLocation(e: DropdownChangeParams) {
    const value = e.value as Location;
    this.setState({selectedLocation: value});
  }

  save() {
    const freightOrders = this.props.freightOrders;
    const location = this.state.selectedLocation;
    if (location?.id) {
      this.setState({loading: true});

      freightOrders?.map((freightOrder: FreightOrder) => {
        const updateOrder: FreightOrderPatch = {
          current_location_id: location.id,
        };

        this.freightOrdersService
          ?.updateFreightOrder(freightOrder.id ?? '', updateOrder)
          .then(() => {
            MessageService.sendMessage(messages.orderUpdated);
            this.twoToast?.showSuccess('Current Location changed succesfully.');
            this.hideDialog();
          })
          .catch(() => {
            this.setState({loading: false});
            this.twoToast?.showError('Sorry, Current Location change failed, please try again.');
          });
      });
    } else {
      this.twoToast?.showWarn('Current Location change failed, location should be selected.');
    }
  }

  locationOptionTemplate(location: Location | undefined) {
    const state = localStorage.getItem('current state') ?? '';
    if (location) {
      if (location.state_id === state) {
        return <span>{location.name}</span>;
      }
      return <span>{`${location.name} ${location.state_id}`}</span>;
    }
    return <></>;
  }

  render() {
    const {locations, selectedLocation} = this.state;

    const dialogBody = (
      <div className="w-100" style={{height: '100%'}}>
        <div className="p-d-flex p-ai-center p-col-12 p-p-0 p-mb-4">
          <span className="p-d-flex p-col-4 p-pl-0">Change the current location of the order(s) below to</span>
          <div className="p-d-flex p-col-8 p-p-0">
            <Dropdown
              className="p-d-flex w-100"
              value={selectedLocation}
              options={locations}
              valueTemplate={this.locationOptionTemplate}
              itemTemplate={this.locationOptionTemplate}
              onChange={e => this.setSelectedLocation(e)}
              optionLabel="name"
            />
          </div>
        </div>
        <div className="p-d-flex" style={{height: '100%'}}>
          <OrderListComponent
            key="change-location-orders"
            id="orders"
            hideMenu={true}
            isAll={true}
            freightOrderIds={this.props.freightOrders.map(o => o.id ?? '')}
            hideFilter={true}
            hideSort={true}
            heightToScroll={'max'}
          />
        </div>
      </div>
    );

    return (
      <>
        <TwoDialog
          headerTitle={'Change Current Location'}
          showDialog={this.props.showDialog}
          width={80}
          onHide={this.hideDialog}
          onSave={this.save}
          loading={this.state.loading}
          onShow={this.loadData}
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}
export default ChangeLocationDialog;
