import React from 'react';
import {AppContext, MessageService, TwoDialog, TwoToast} from 'two-app-ui';
import {
  FreightOrder,
  FreightProof,
  FreightProofFile,
  Run,
  Stop,
  Location,
  FreightOrderAggregate,
  StopAggregate,
  FreightProofAggregate,
} from 'two-core';
import {NotePanel} from './Sections/NotePanel';
import {SignaturePanel} from './Sections/SignaturePanel';
import {PhotosPanel} from './Sections/PhotosPanel';
import {OrdersPanel} from './Sections/OrdersPanel';
import {Button} from 'primereact/button';
import {ScrollPanel} from 'primereact/scrollpanel';
import SignatureCanvas from 'react-signature-canvas';
import FreightProofsService from '../../../services/FreightProofsService';
import {SelectionsPanel} from './Sections/SelectionsPanel';
import {AddOrdersToProofDialog} from '../AddOrdersToProofDialog';
import {messages} from '../../../config/messages';
import FreightOrdersService from '../../../services/FreightOrdersService';
import StopsService from '../../../services/StopsService';
import './AddEditProofDialog.scss';
import RunsService from '../../../services/RunsService';
import {confirmDialog} from 'primereact/confirmdialog';

type Mode =
  | 'new_proof_from_stop'
  | 'edit_proof_from_stop'
  | 'edit_proof_from_location'
  | 'new_proof_from_order'
  | 'edit_proof_from_order';
interface Props {
  showDialog: boolean;
  onHide: () => void;
  /**
   * Order to create/edit proof for
   */
  orderId?: string;
  /**
   * Location to edit proof for
   */
  locationId?: number;
  /**
   * Stop to create/edit proof for
   */
  stopId?: number;
  /**
   * Proof id to edit
   */
  proofId?: number;
}
interface State {
  loading: boolean;
  loadingRuns: boolean;
  loadingStops: boolean;
  saving: boolean;
  collapsePhotos: boolean;
  collapseSignature: boolean;
  collapseNote: boolean;
  includePhotos: boolean;
  includeSignature: boolean;
  includeNote: boolean;
  showAddOrdersDialog: boolean;
  proof?: FreightProof;
  proofPatch: Partial<FreightProof>;
  selectedRun?: Run;
  selectedLocation?: Location;
  loadedOrders: FreightOrder[];
  runs: Run[];
  locations: Location[];
  signatureFile?: File;
  mode?: Mode;
  newFiles?: File[];
  photoUrlsToDelete: string[];
  signatureUrlToDelete?: string;
  stopsMap: Map<number, Stop[]>; // run_id -> stop[]
  runNameFilter?: string;
}

export class AddEditProofDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  freightProofsService?: FreightProofsService;
  freightOrdersService?: FreightOrdersService;
  stopsService?: StopsService;
  runsService?: RunsService;
  twoToast?: TwoToast;
  signatureCanvasRef: React.RefObject<SignatureCanvas>;
  runNameFilterTimer?: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      loadingRuns: false,
      loadingStops: false,
      saving: false,
      collapsePhotos: false,
      collapseSignature: true,
      collapseNote: true,
      includePhotos: false,
      includeSignature: true,
      includeNote: true,
      showAddOrdersDialog: false,
      proofPatch: {},
      runs: [],
      locations: [],
      loadedOrders: [],
      photoUrlsToDelete: [],
      stopsMap: new Map(),
    };

    this.signatureCanvasRef = React.createRef();

    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onAddOrdersClick = this.onAddOrdersClick.bind(this);
    this.onOrderSwitch = this.onOrderSwitch.bind(this);
    this.onAddOrders = this.onAddOrders.bind(this);
    this.onTempPhotoDelete = this.onTempPhotoDelete.bind(this);
    this.onPhotoDelete = this.onPhotoDelete.bind(this);
    this.onProofChange = this.onProofChange.bind(this);
    this.onFilesSelect = this.onFilesSelect.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onPhotosTurnOff = this.onPhotosTurnOff.bind(this);
    this.onPhotosVisibilityToggle = this.onPhotosVisibilityToggle.bind(this);
    this.onSignatureTurnOff = this.onSignatureTurnOff.bind(this);
    this.onSignatureVisibilityToggle = this.onSignatureVisibilityToggle.bind(this);
    this.onNoteVisibilityToggle = this.onNoteVisibilityToggle.bind(this);
    this.onNoteTurnOff = this.onNoteTurnOff.bind(this);
    this.onRunChange = this.onRunChange.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onRunNameFilterChange = this.onRunNameFilterChange.bind(this);
  }

  componentDidMount() {
    this.freightProofsService = this.context.freightProofsService;
    this.twoToast = this.context.twoToast;
    this.freightOrdersService = this.context.freightOrdersService;
    this.stopsService = this.context.stopsService;
    this.runsService = this.context.runsService;
  }

  async loadData(proofId?: number, orderId?: string, locationId?: number, stopId?: number) {
    this.setState({loading: true});
    let initState: Partial<State> = {
      collapseSignature: true,
      collapseNote: true,
      collapsePhotos: false,
      includePhotos: true,
      includeSignature: false,
      includeNote: false,
    };
    try {
      if (proofId) {
        if (stopId) {
          initState = await this.initByProofId(proofId, stopId);
        } else if (locationId) {
          initState = await this.initByProofId(proofId, undefined, locationId);
        } else if (orderId) {
          initState = await this.initByProofId(proofId, undefined, undefined, orderId);
        }
      } else {
        if (stopId) {
          initState = await this.initByStopId(stopId);
        } else if (orderId) {
          initState = await this.initByOrderId(orderId);
        }
      }
    } catch (error) {
      console.error('Failed to init data', error);
      return;
    }

    if (!initState.mode) {
      console.error('Proof dialog mode is not set, some data may be missing.');
      return;
    }

    this.setState(() => ({
      loading: false,
      mode: initState.mode,
      loadedOrders: initState.loadedOrders ?? [],
      collapseSignature: initState.collapseSignature !== undefined ? initState.collapseSignature : true,
      collapseNote: initState.collapseNote !== undefined ? initState.collapseNote : true,
      collapsePhotos: initState.collapsePhotos !== undefined ? initState.collapsePhotos : false,
      includePhotos: initState.includePhotos !== undefined ? initState.includePhotos : true,
      includeSignature: initState.includeSignature !== undefined ? initState.includeSignature : false,
      includeNote: initState.includeNote !== undefined ? initState.includeNote : false,
      runs: initState.runs ?? [],
      selectedRun: initState.selectedRun,
      locations: initState.locations ?? [],
      selectedLocation: initState.selectedLocation,
      proof: initState.proof,
      proofPatch: initState.proofPatch ?? {},
      stopsMap: initState.stopsMap ?? new Map<number, Stop[]>(),
    }));
  }

  async initByProofId(proofId: number, stopId?: number, locationId?: number, orderId?: string) {
    const proof = await this.loadProof(proofId);
    if (!proof) {
      throw new Error('Proof not found');
    }
    return this.initByProof(proof, stopId, locationId, orderId);
  }

  async initByProof(proof: FreightProof, stopId?: number, locationId?: number, orderId?: string) {
    let mode: Mode | undefined = undefined;
    if (stopId && proof.stop_id) {
      mode = 'edit_proof_from_stop';
    } else if (locationId && proof.location_id === locationId) {
      if (proof.stop_id) {
        mode = 'edit_proof_from_stop';
      } else {
        mode = 'edit_proof_from_location';
      }
    } else if (orderId && proof.order_ids?.includes(orderId)) {
      mode = 'edit_proof_from_order';
    }
    let loadedOrders: FreightOrder[] = [];
    if (proof.order_ids?.length) {
      loadedOrders = (await this.loadFreightOrders(proof.order_ids)) ?? [];
    }
    let runs: Run[] = [];
    let locations: Location[] = [];
    let selectedRun: Run | undefined = undefined;
    let selectedLocation: Location | undefined = undefined;
    const stopsMap = new Map<number, Stop[]>();
    let collapsePhotos = false;
    let collapseSignature = true;
    let collapseNote = true;
    let includePhotos = true;
    let includeSignature = false;
    let includeNote = false;
    if (mode === 'edit_proof_from_stop') {
      //Editing proof from stop, the run and location selection will be disabled.
      const stop = (await this.loadStops(proof.stop_id!))?.[0];
      if (!stop) {
        throw new Error('Stop not found');
      }
      stopsMap.set(stop.run_id, [stop]);
      const location = stop?.stop_location;
      if (!location) {
        throw new Error('Location not found');
      }
      locations = [location];
      selectedLocation = location;
      const run = (await this.loadRuns([stop.run_id!], undefined, undefined, undefined, true))?.[0];
      if (!run) {
        throw new Error('Run not found');
      }
      runs = [run];
      selectedRun = run;
    } else if (mode === 'edit_proof_from_location') {
      // Editing proof from the location, the proof doesn't have stop_id. The run and location selection will be disabled.
      const location = proof.location;
      if (!location) {
        throw new Error('Location not found');
      }
      locations = [location];
      selectedLocation = location;
    } else if (mode === 'edit_proof_from_order') {
      // Editing proof from the order.
      if (proof.stop_id) {
        //The proof has a stop. Load all common runs in selected orders.
        runs = (await this.loadRuns(undefined, proof.order_ids!, undefined, undefined, true)) ?? [];
        selectedRun = runs.find(run => run.stops?.some(stop => stop.id === proof.stop_id));
        if (!selectedRun) {
          //try find run by stopId
          runs = (await this.loadRuns(undefined, undefined, undefined, proof.stop_id, true)) ?? [];
          selectedRun = runs.find(run => run.stops?.some(stop => stop.id === proof.stop_id));
        }
        if (!selectedRun) {
          throw new Error('Selected run not found');
        }
        //Load all stops of runs, which have tasks for selected orders.
        let stops =
          (await this.loadStops(
            undefined,
            runs.map(run => run.id!),
            proof.order_ids
          )) ?? [];
        for (const stop of stops) {
          if (!stopsMap.has(stop.run_id)) {
            stopsMap.set(stop.run_id, []);
          }
          stopsMap.get(stop.run_id)!.push(stop);
        }
        let selectedStop = stops.find(stop => stop.id === proof.stop_id);
        if (!selectedStop) {
          //try to find a stop by runId only
          stops = (await this.loadStops(undefined, [selectedRun.id!])) ?? [];
          for (const stop of stops) {
            if (!stopsMap.has(stop.run_id)) {
              stopsMap.set(stop.run_id, []);
            }
            stopsMap.get(stop.run_id)!.push(stop);
          }
          selectedStop = stops.find(stop => stop.id === proof.stop_id);
        }
        if (!selectedStop) {
          throw new Error('Stop not found');
        }
        locations = stopsMap.get(selectedStop.run_id)!.map(stop => stop.stop_location!);
        selectedLocation = selectedStop?.stop_location;
      } else if (proof.location_id) {
        //The proof has a location only.
        const location = proof.location;
        if (!location) {
          throw new Error('Location not found');
        }
        locations = [location];
        selectedLocation = location;
      } else {
        throw new Error('Location not found');
      }
    } else {
      throw new Error('Invalid mode');
    }
    if (proof.photos?.length) {
      collapsePhotos = false;
      includePhotos = true;
    }
    if (proof.signee_name?.length) {
      collapseSignature = false;
      includeSignature = true;
    }
    if (proof.note?.length) {
      collapseNote = false;
      includeNote = true;
    }
    return {
      loadedOrders,
      runs,
      selectedRun,
      locations,
      selectedLocation,
      proof,
      mode,
      stopsMap,
      collapsePhotos,
      collapseSignature,
      collapseNote,
      includePhotos,
      includeSignature,
      includeNote,
    };
  }

  async initByOrderId(orderId: string) {
    const freightOrder = (await this.loadFreightOrders([orderId]))?.[0];
    if (!freightOrder) {
      throw new Error('Order not found');
    }
    return this.initByOrder(freightOrder);
  }

  async initByOrder(order: FreightOrder) {
    const mode: Mode = 'new_proof_from_order';
    const loadedOrders = [order];
    const proofPatch: Partial<FreightProof> = {order_ids: [order.id!]};
    const locations = [];
    let selectedLocation: Location | undefined = undefined;
    const stopsMap = new Map<number, Stop[]>();
    // load all runs where the order is present
    let runs = (await this.loadRuns(undefined, [order.id!], undefined, undefined, true)) ?? [];
    if (runs.length) {
      //Load all stops of runs, which have tasks for selected orders.
      const stops =
        (await this.loadStops(
          undefined,
          runs.map(run => run.id!),
          [order.id!]
        )) ?? [];
      for (const stop of stops) {
        if (!stopsMap.has(stop.run_id)) {
          stopsMap.set(stop.run_id, []);
        }
        stopsMap.get(stop.run_id)!.push(stop);
      }
    } else {
      // Order has no runs.
      runs = (await this.loadRuns(undefined, undefined, undefined, undefined, true)) ?? [];
      if (order.location) {
        locations.push(order.location);
        selectedLocation = order.location;
      }
    }
    return {loadedOrders, runs, locations, selectedLocation, mode, stopsMap, proofPatch};
  }

  async initByStopId(stopId: number) {
    const stop = (await this.loadStops(stopId))?.[0];
    if (!stop) {
      throw new Error('Stop not found');
    }
    return this.initByStop(stop);
  }

  async initByStop(stop: Stop) {
    const mode: Mode = 'new_proof_from_stop';
    const run = (await this.loadRuns([stop.run_id!], undefined, undefined, undefined, true))?.[0];
    if (!run) {
      throw new Error('Run not found');
    }
    const runs = [run];
    const selectedRun = run;
    const locations = [stop.stop_location!];
    const selectedLocation = stop.stop_location!;
    const proofPatch: Partial<FreightProof> = {stop_id: stop.id, location_id: stop.stop_location!.id};

    return {runs, selectedRun, locations, selectedLocation, mode, proofPatch};
  }

  async loadRuns(runIds?: number[], orderIds?: string[], runName?: string, stopId?: number, aggregate?: boolean) {
    this.setState(() => ({loadingRuns: true}));
    const filters = [];
    if (runIds?.length) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: runIds,
          condition: 'in',
        })
      );
    }
    if (orderIds?.length) {
      filters.push(
        JSON.stringify({
          field: 'task.freight_order_id',
          value: orderIds,
          condition: 'in',
        })
      );
    }
    if (runName?.length) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: runName,
          condition: 'iLike',
        })
      );
    }
    if (stopId) {
      filters.push(
        JSON.stringify({
          field: 'stop.id',
          value: stopId,
        })
      );
    }
    if (!filters.length) {
      //default filter
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: 'Done',
          condition: '<>',
        })
      );
    }
    //todo run aggregation needs rework on backend
    return this.runsService
      ?.getRuns({filters, aggregate})
      .then(data => {
        return (data?.records as Run[]) ?? [];
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading runs, please refresh and try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState(() => ({loadingRuns: false})));
  }

  async loadStops(stopId?: number, runIds?: number[], orderIds?: string[]) {
    this.setState(() => ({loadingStops: true}));
    const filters: string[] = [];
    if (stopId) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: stopId,
        })
      );
    }
    if (runIds?.length) {
      filters.push(
        JSON.stringify({
          field: 'run_id',
          value: runIds,
          condition: 'in',
        })
      );
    }
    if (orderIds?.length) {
      filters.push(
        JSON.stringify({
          field: 'task.freight_order_id',
          value: orderIds,
          condition: 'in',
        })
      );
    }
    const aggregate: StopAggregate[] = ['stop_location', 'tasks'];
    return this.stopsService
      ?.getStops({filters, aggregate})
      .then(data => {
        return (data?.records as Stop[]) ?? [];
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading stop, please refresh and try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState(() => ({loadingStops: false})));
  }

  async loadProof(proofId?: number, stopId?: number) {
    const filters: string[] = [];
    if (proofId) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: proofId,
        })
      );
    }
    if (stopId) {
      filters.push(
        JSON.stringify({
          field: 'stop_id',
          value: stopId,
        })
      );
    }
    const aggregate: FreightProofAggregate[] = ['order_ids', 'location'];
    return this.freightProofsService
      ?.getFreightProofs({filters, aggregate})
      .then(data => {
        return ((data?.records as FreightProof[]) ?? [])[0];
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading proof, please refresh and try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadFreightOrders(ids: string[]) {
    const filters = [
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      }),
    ];
    const aggregate: FreightOrderAggregate[] = ['order', 'factory_order', 'shipment_items', 'location'];

    return this.freightOrdersService
      ?.getFreightOrders({filters, aggregate})
      .then(data => {
        return (data?.records as FreightOrder[]) ?? [];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, orders load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  onSaveClick() {
    const {
      proofPatch,
      signatureUrlToDelete,
      includeSignature,
      includePhotos,
      includeNote,
      newFiles,
      photoUrlsToDelete,
      proof,
    } = this.state;

    if (
      !this.validate(
        proofPatch,
        proof,
        newFiles,
        photoUrlsToDelete,
        signatureUrlToDelete,
        this.signatureCanvasRef.current
      )
    ) {
      return;
    }
    const deleteParams = [];
    if (!includePhotos && proof?.photos?.length) {
      deleteParams.push('photos');
    }
    if (!includeNote && proof?.note?.length) {
      deleteParams.push('note');
    }
    if (!includeSignature && proof?.signature_picture?.length) {
      deleteParams.push('signature');
    }
    if (deleteParams.length) {
      const message = `Are you sure you want to remove already saved data? If you save existing ${deleteParams.join(', ')} will be deleted.`;
      confirmDialog({
        message,
        header: 'Confirmation',
        accept: () => this.onSave(proofPatch, photoUrlsToDelete, signatureUrlToDelete, proof, newFiles),
        className: 'remove-confirmation-dialog',
      });
    } else {
      this.onSave(proofPatch, photoUrlsToDelete, signatureUrlToDelete, proof, newFiles);
    }
  }

  async onSave(
    proofPatch: Partial<FreightProof>,
    photoUrlsToDelete: string[],
    signatureUrlToDelete?: string,
    proof?: FreightProof,
    newFiles?: File[]
  ) {
    this.setState(() => ({saving: true}));
    if (proof) {
      const newProofFiles = await this.uploadPhotos(proof.id, newFiles);
      const updatedPatch = {...proofPatch};
      if (newProofFiles?.length) {
        updatedPatch.photos = [...(proof.photos ?? []), ...newProofFiles];
      }
      if (photoUrlsToDelete.length) {
        await this.deletePhotos(proof.id, photoUrlsToDelete);
        if (updatedPatch.photos?.length) {
          updatedPatch.photos = updatedPatch.photos.filter(photo => !photoUrlsToDelete.includes(photo.url));
        } else {
          updatedPatch.photos = (proof.photos ?? []).filter(photo => !photoUrlsToDelete.includes(photo.url));
        }
      }
      const newSignatureUrl = await this.uploadSignature(proof.id, this.signatureCanvasRef.current);
      if (newSignatureUrl) {
        updatedPatch.signature_picture = newSignatureUrl;
      } else if (signatureUrlToDelete?.length) {
        await this.deleteSignature(proof.id, signatureUrlToDelete);
      }
      const updatedProof = await this.updateProof(proof.id, updatedPatch);
      if (updatedProof) {
        MessageService.sendMessage(messages.proofChanged);
        this.onHide();
      }
    } else {
      const orderIds = proofPatch.order_ids!.filter(n => n);
      // const orderIds = proofPatch.order_ids ?? stop?.tasks?.map(task => task.freight_order_id!);
      const createdProof = await this.createProof({
        ...proofPatch,
        order_ids: orderIds,
        taken_at: new Date(),
      });
      if (createdProof) {
        const newProofFiles = await this.uploadPhotos(createdProof.id, newFiles);
        const updatedPatch: Partial<FreightProof> = {};
        if (newProofFiles?.length) {
          updatedPatch.photos = newProofFiles;
        }
        const newSignatureUrl = await this.uploadSignature(createdProof.id, this.signatureCanvasRef.current);
        if (newSignatureUrl) {
          updatedPatch.signature_picture = newSignatureUrl;
        }
        if (updatedPatch.photos || updatedPatch.signature_picture) {
          const updatedProof = await this.updateProof(createdProof.id, updatedPatch);
          if (updatedProof) {
            MessageService.sendMessage(messages.proofChanged);
            this.onHide();
          }
        } else {
          MessageService.sendMessage(messages.proofChanged);
          this.onHide();
        }
      }
    }
    this.setState(() => ({saving: false}));
  }

  validate(
    proofPatch: Partial<FreightProof>,
    proof?: Partial<FreightProof>,
    newFiles?: File[],
    photoUrlsToDelete?: string[],
    signatureUrlToDelete?: string,
    currSignatureCanvas?: SignatureCanvas | null
  ): boolean {
    const errors: string[] = [];

    if (proofPatch.order_ids && proofPatch.order_ids.length === 0) {
      errors.push('The proof must have at least one order.');
    }
    if (!(proofPatch.location_id || proof?.location_id || proof?.stop_id)) {
      errors.push('The location must be set.');
    }
    const signeeName = proofPatch.signee_name ?? proof?.signee_name;
    let signaturePictureExist = false;
    if (
      (currSignatureCanvas && !currSignatureCanvas.isEmpty()) ||
      (proof?.signature_picture && !signatureUrlToDelete?.length)
    ) {
      signaturePictureExist = true;
    }
    const newPhotoUrls = newFiles?.map(file => URL.createObjectURL(file)) ?? [];
    const savedPhotoUrls =
      proof?.photos?.map(photo => photo.url).filter(url => !photoUrlsToDelete?.includes(url)) ?? [];
    const mergedPhotoUrls = [...newPhotoUrls, ...savedPhotoUrls];
    if (!(signeeName && signaturePictureExist) && mergedPhotoUrls.length === 0) {
      errors.push('The proof must have a signature or at least one photo.');
    }
    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.twoToast?.showError(errorText);
      return false;
    }
    return true;
  }

  async updateProof(id: number, proofPatch: Partial<FreightProof>) {
    if (Object.values(proofPatch).length) {
      return this.freightProofsService!.updateFreightProof(id, proofPatch)
        .then(proof => {
          this.twoToast?.showSuccess('Freight proof updated successfully.');
          return proof;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, freight proof update failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return;
  }

  async uploadPhotos(proofId: number, newFiles?: File[]): Promise<FreightProofFile[] | undefined> {
    if (newFiles?.length) {
      const promises = [];
      for (const newFile of newFiles) {
        promises.push(this.freightProofsService?.uploadPhoto(proofId, newFile));
      }
      return Promise.all(promises)
        .then(responses => {
          this.twoToast?.showSuccess('Photos uploaded successfully.');
          return responses.map(response => {
            const url = new URL(response!.config.url!);
            const proofFile: FreightProofFile = {url: `${url.origin}${url.pathname}`, uploaded_at: new Date()};
            return proofFile;
          });
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos upload failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async deletePhotos(proofId: number, photoUrlsToDelete: string[]) {
    console.log(photoUrlsToDelete);
    if (photoUrlsToDelete?.length) {
      const promises = [];
      for (const urlToDelete of photoUrlsToDelete) {
        promises.push(this.freightProofsService?.deletePhoto(proofId, urlToDelete));
      }
      return Promise.all(promises)
        .then(() => {
          this.twoToast?.showSuccess('Photos deleted successfully.');
          return true;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos delete failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async deleteSignature(proofId: number, signatureUrlToDelete: string) {
    if (signatureUrlToDelete?.length) {
      return this.freightProofsService
        ?.deletePhoto(proofId, signatureUrlToDelete)
        .then(() => {
          this.twoToast?.showSuccess('Signature deleted successfully.');
          return true;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, signature delete failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async uploadSignature(proofId: number, currSignatureCanvas?: SignatureCanvas | null): Promise<string | undefined> {
    if (currSignatureCanvas && !currSignatureCanvas.isEmpty()) {
      const signatureDataUrl = currSignatureCanvas.getCanvas().toDataURL();
      const signatureFile = await this.dataUrlToFile(signatureDataUrl, 'signature.png');
      return this.freightProofsService
        ?.uploadSignature(proofId, signatureFile)
        .then(response => {
          this.twoToast?.showSuccess('Signature uploaded successfully.');
          const url = new URL(response!.config.url!);
          return `${url.origin}${url.pathname}`;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, signature upload failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async dataUrlToFile(url: string, filename: string) {
    const arr = url.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  createProof(proofPatch: Partial<FreightProof>) {
    return this.freightProofsService!.createFreightProof(proofPatch)
      .then(proof => {
        this.twoToast?.showSuccess('Freight proof created successfully.');
        return proof;
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, freight proof creation failed, please try again.');
        console.error('error: ' + error);
        return undefined;
      });
  }

  onAddOrdersClick() {
    this.setState({
      showAddOrdersDialog: true,
    });
  }

  async onDownload(url: string) {
    const blobUrl = await this.freightProofsService?.getPhotoBlobUrl(url);
    if (!blobUrl?.length) {
      this.twoToast?.showError('Sorry, photo download failed, please try again.');
      return;
    }
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = '';
    a.click();
  }

  onOrderSwitch(freightOrder: FreightOrder) {
    this.setState(
      (state, props) => {
        const {proofPatch, loadedOrders, proof} = state;
        const selectedProofOrders = this.getSelectedProofOrders(proofPatch, proof, loadedOrders);
        const selectedOrderIds = selectedProofOrders?.map(selectedOrder => selectedOrder.id!);
        const newSelectedOrderIds: string[] = [];
        if (selectedOrderIds.includes(freightOrder.id!)) {
          newSelectedOrderIds.push(...selectedOrderIds.filter(id => id !== freightOrder.id));
        } else {
          newSelectedOrderIds.push(...selectedOrderIds, freightOrder.id!);
        }
        return {proofPatch: {...proofPatch, order_ids: newSelectedOrderIds}};
      },
      () => MessageService.sendMessage(messages.proofOrdersChanged)
    );
  }

  onLocationChange(selectedLocation: Location) {
    this.setState(state => {
      let selectedStopId: number | undefined = undefined;
      if (state.selectedRun) {
        const selectedStop = state.stopsMap
          .get(state.selectedRun.id!)
          ?.find(stop => stop.stop_location?.id === selectedLocation.id);
        if (selectedStop) {
          selectedStopId = selectedStop.id;
        }
      }
      return {
        selectedLocation,
        proofPatch: {...state.proofPatch, stop_id: selectedStopId, location_id: selectedLocation.id},
      };
    });
  }

  onAddOrders(freightOrders: FreightOrder[]) {
    if (!freightOrders.length) {
      return;
    }
    this.setState((state, props) => {
      const {proofPatch, loadedOrders, proof} = state;
      const orderIds = proofPatch?.order_ids ?? proof?.order_ids ?? [];
      return {
        proofPatch: {...proofPatch, order_ids: [...orderIds, ...freightOrders.map(order => order.id!)]},
        loadedOrders: [...loadedOrders, ...freightOrders],
      };
    });
  }

  onPhotosTurnOff() {
    this.setState(state => {
      const photoUrlsToDelete = state.proof?.photos?.map(photo => photo.url) ?? [];
      return {
        collapsePhotos: true,
        includePhotos: false,
        photoUrlsToDelete,
        newFiles: undefined,
      };
    });
  }

  onPhotosVisibilityToggle(collapse: boolean) {
    this.setState(state => ({collapsePhotos: collapse, includePhotos: !collapse ? true : state.includePhotos}));
  }

  onSignatureTurnOff() {
    this.setState(state => ({
      collapseSignature: true,
      includeSignature: false,
      signatureFile: undefined,
      signatureUrlToDelete: state?.proof?.signature_picture,
      proofPatch: {...this.state.proofPatch, signee_name: '', signature_picture: ''},
    }));
  }

  onSignatureVisibilityToggle(collapse: boolean) {
    this.setState(state => ({
      collapseSignature: collapse,
      includeSignature: !collapse ? true : state.includeSignature,
    }));
  }

  onNoteVisibilityToggle(collapse: boolean) {
    this.setState(state => ({collapseNote: collapse, includeNote: !collapse ? true : state.includeNote}));
  }

  onNoteTurnOff() {
    this.setState(state => ({collapseNote: true, includeNote: false, proofPatch: {...state.proofPatch, note: ''}}));
  }

  getSelectedProofOrders(
    proofPatch: Partial<FreightProof>,
    proof: FreightProof | undefined,
    orders: FreightOrder[] | undefined
  ): FreightOrder[] {
    if (proofPatch?.order_ids) {
      return orders!.filter(order => proofPatch.order_ids?.includes(order.id!));
    }
    if (proof?.order_ids) {
      return orders!.filter(order => proof.order_ids?.includes(order.id!));
    }
    return [];
  }

  onTempPhotoDelete(index: number) {
    this.setState(state => {
      const updatedFiles = [...state.newFiles!];
      updatedFiles.splice(index, 1);
      return {
        newFiles: updatedFiles,
      };
    });
  }

  onPhotoDelete(url: string) {
    console.log(url);
    this.setState(state => {
      const updatedUrls = [...state.photoUrlsToDelete, url];
      return {
        photoUrlsToDelete: updatedUrls,
      };
    });
  }

  onProofChange(partialProof: Partial<FreightProof>) {
    this.setState(state => {
      return {proofPatch: {...state.proofPatch, ...partialProof}};
    });
  }

  onFilesSelect(selectedFiles: File[]) {
    this.setState(state => {
      const renamedFiles = selectedFiles.map((file, index) => {
        const fileExtension = file.name.split('.')[1].toLowerCase();
        const newFileName = `${Date.now()}${index}.${fileExtension}`;
        return new File([file], newFileName, {type: file.type});
      });
      return {newFiles: [...(state.newFiles ?? []), ...renamedFiles]};
    });
  }

  onCancel() {
    this.onHide();
  }

  onHide() {
    this.setState({
      loading: false,
      loadingRuns: false,
      saving: false,
      collapsePhotos: false,
      collapseSignature: true,
      collapseNote: true,
      showAddOrdersDialog: false,
      proofPatch: {},
      runs: [],
      locations: [],
      loadedOrders: [],
      newFiles: undefined,
      selectedLocation: undefined,
      signatureFile: undefined,
      selectedRun: undefined,
      photoUrlsToDelete: [],
    });
    this.props.onHide();
  }

  onShow() {
    const {orderId, proofId, locationId, stopId} = this.props;
    this.loadData(proofId, orderId, locationId, stopId);
  }

  async onRunChange(selectedRun: Run) {
    const {stopsMap, proofPatch} = this.state;
    const newStopsMap = new Map(stopsMap);
    if (!newStopsMap.has(selectedRun.id!)) {
      const runStops = (await this.loadStops(undefined, [selectedRun.id!])) ?? [];
      newStopsMap.set(selectedRun.id!, runStops);
    }
    const stops = newStopsMap.get(selectedRun.id!) ?? [];
    const locations = stops.map(stop => stop.stop_location!);
    this.setState({
      selectedRun,
      locations,
      selectedLocation: undefined,
      proofPatch: {...proofPatch, location_id: undefined, stop_id: undefined},
      stopsMap: newStopsMap,
    });
  }

  onRunNameFilterChange(name: string) {
    if (this.runNameFilterTimer) {
      clearTimeout(this.runNameFilterTimer);
    }
    this.runNameFilterTimer = setTimeout(async () => {
      const runs = await this.loadRuns(undefined, undefined, name, undefined, false);

      this.setState(state => ({
        runs: runs ?? [],
        selectedRun: undefined,
        locations: [],
        selectedLocation: undefined,
        proofPatch: {...state.proofPatch, location_id: undefined, stop_id: undefined},
      }));
    }, 500);
  }

  render() {
    const {showDialog} = this.props;
    const {
      saving,
      loadingRuns,
      loadingStops,
      proofPatch,
      collapseNote,
      collapseSignature,
      collapsePhotos,
      includeSignature,
      includeNote,
      includePhotos,
      newFiles,
      selectedRun,
      selectedLocation,
      runs,
      locations,
      showAddOrdersDialog,
      loading,
      loadedOrders,
      proof,
      photoUrlsToDelete,
      signatureUrlToDelete,
      mode,
    } = this.state;
    const newPhotoUrls = newFiles?.map(file => URL.createObjectURL(file)) ?? [];
    const savedPhotoUrls = proof?.photos?.map(photo => photo.url).filter(url => !photoUrlsToDelete.includes(url)) ?? [];
    const mergedPhotoUrls = [...newPhotoUrls, ...savedPhotoUrls];
    const selectedProofOrders = this.getSelectedProofOrders(proofPatch, proof, loadedOrders);
    const footer = (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className="p-mr-2 p-button-text" onClick={this.onCancel} />
        <Button label="Save" className="p-mr-2" onClick={this.onSaveClick} loading={saving} />
      </div>
    );
    let signatureFileUrl = '';
    if (proof?.signature_picture && !signatureUrlToDelete?.length) {
      signatureFileUrl = proof.signature_picture ?? '';
    }
    const selectedStopId = proof?.stop_id ?? proofPatch?.stop_id ?? undefined;
    return (
      <>
        <TwoDialog
          header={proof ? 'Edit Proof' : 'Add Proof'}
          footer={footer}
          loading={loading}
          onHide={this.onHide}
          onShow={this.onShow}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          id="add_edit_proof_dialog"
        >
          <ScrollPanel id="proof_component" className="w-100">
            <SelectionsPanel
              disabled={
                saving ||
                loadingRuns ||
                loadingStops ||
                mode === 'edit_proof_from_stop' ||
                mode === 'edit_proof_from_location' ||
                mode === 'new_proof_from_stop'
              }
              runs={runs}
              locations={locations}
              selectedRun={selectedRun}
              selectedLocation={selectedLocation}
              onRunChange={this.onRunChange}
              onLocationChange={this.onLocationChange}
              onRunNameFilterChange={this.onRunNameFilterChange}
              loadingRuns={loadingRuns}
            />
            <OrdersPanel
              proofOrders={loadedOrders}
              selectedProofOrders={selectedProofOrders}
              disabled={saving}
              onAddOrders={this.onAddOrdersClick}
              onOrderSwitch={this.onOrderSwitch}
              hideActions={mode === 'edit_proof_from_order' || mode === 'new_proof_from_order'}
            />
            <PhotosPanel
              collapsed={collapsePhotos}
              disabled={saving}
              active={includePhotos}
              photoUrls={mergedPhotoUrls}
              onVisibilityToggle={this.onPhotosVisibilityToggle}
              onFilesSelect={this.onFilesSelect}
              onTempPhotoDelete={this.onTempPhotoDelete}
              onPhotoDelete={this.onPhotoDelete}
              onTurnOff={this.onPhotosTurnOff}
              onDownload={this.onDownload}
            />
            <NotePanel
              note={proofPatch.note ?? proof?.note ?? ''}
              collapsed={collapseNote}
              active={includeNote}
              disabled={saving}
              onVisibilityToggle={this.onNoteVisibilityToggle}
              onNoteChange={(value: string) => this.onProofChange({note: value})}
              onTurnOff={this.onNoteTurnOff}
            />
            <SignaturePanel
              collapsed={collapseSignature}
              disabled={saving}
              active={includeSignature}
              name={proofPatch.signee_name ?? proof?.signee_name ?? ''}
              signatureFileUrl={signatureFileUrl}
              onVisibilityToggle={this.onSignatureVisibilityToggle}
              onNameChange={(value: string) => this.onProofChange({signee_name: value})}
              signatureCanvasRef={this.signatureCanvasRef}
              onTurnOff={this.onSignatureTurnOff}
            />
            <AddOrdersToProofDialog
              showDialog={showAddOrdersDialog}
              onHide={() => this.setState({showAddOrdersDialog: false})}
              proofOrders={loadedOrders}
              onAddOrders={this.onAddOrders}
              stopId={selectedStopId}
            />
          </ScrollPanel>
        </TwoDialog>
      </>
    );
  }
}
