import React from 'react';
import OrderListComponent from '../Orders/OrderListComponent';

export class StorageOrdersPage extends React.Component<{}> {
  constructor(props = {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent freightOrderOptionsType="Storage" />;
  }
}
