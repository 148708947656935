import React from 'react';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService} from 'two-app-ui';
import {History} from 'history';
import {
  FreightOrder,
  FreightOrderPatch,
  FreightStage,
  OrderPatch,
  QueryParameter,
  Run,
  Stop,
  Task,
  TimeLineEvent,
  TleContentUnassigned,
  TleLink,
} from 'two-core';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import AddOrdersToRunDialog from '../Runs/AddOrdersToRunDialog';
import FreightOrdersService from '../../services/FreightOrdersService';
import OrdersService from '../../services/OrdersService';
import StopsService from '../../services/StopsService';
import TasksService from '../../services/TasksService';
import TlesService from '../../services/TlesService';
import AssignOrdersToRunDialog from '../Orders/AssignOrdersToRunDialog';
import {messages} from '../../config/messages';
import GroupOrdersComponent from '../GroupOrders/GroupOrdersComponent';

interface Props {
  run: Run;
  history: History;
  toast: React.RefObject<Toast>;
}

interface State {
  run: Run;
  stops: Stop[];
  selectedItems: FreightOrder[];
  showAddOrderToRunDialog: boolean;
  showAssignToRunDialog: boolean;
  isAdditionalRunAssignmentDialog?: boolean;
  removeOrderFromRun: string | undefined;
}

class RunOrders extends React.Component<Props, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      run: {
        stage: 'Draft',
        name: '',
        state_id: '',
      },
      stops: [],
      selectedItems: [],
      showAddOrderToRunDialog: false,
      showAssignToRunDialog: false,
      removeOrderFromRun: undefined,
    };

    this.initMenuItems = this.initMenuItems.bind(this);
  }

  componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;

    this.setRun(this.props.run);
  }

  setRun(run: Run) {
    this.setState({
      run: run,
    });

    this.loadStops(run.id?.toString() ?? '');
  }

  async loadStops(runId: string) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: runId,
      })
    );

    const params: QueryParameter = {
      aggregate: true,
      filters: filters,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const dataRecords = (data?.records as Stop[]) ?? [];

        this.setState({
          stops: dataRecords,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  initMenuItems(selectedOrders: FreightOrder[]): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = selectedOrders;
    const selectedItemsCount = selectedItems.length;

    const addMoreMenu: AppMenuItem = {
      label: 'Add More',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddRuDialog();
      },
    };
    menuItems.push(addMoreMenu);

    if (selectedItemsCount > 0) {
      const removeMenu: AppMenuItem = {
        label: 'Remove from Run',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.removeOrdersFromRun(selectedItems);
        },
      };
      menuItems.push(removeMenu);

      const reAssignMenu: AppMenuItem = {
        label: 'Re-assign',
        faIcon: ['far', 'exchange'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAssignToRunDialogWithRemoveRun(selectedItems);
        },
      };
      menuItems.push(reAssignMenu);

      const assignToAnotherMenu: AppMenuItem = {
        label: 'Assign to another Run',
        faIcon: ['far', 'truck'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAssignToRunDialog(selectedItems);
        },
      };
      menuItems.push(assignToAnotherMenu);
    }

    return menuItems;
  }

  showAddRuDialog() {
    this.setState({showAddOrderToRunDialog: true});
  }

  showAssignToRunDialog(selectedFreightOrders: FreightOrder[]) {
    this.setState({
      showAssignToRunDialog: true,
      selectedItems: selectedFreightOrders,
      isAdditionalRunAssignmentDialog: true,
      removeOrderFromRun: undefined,
    });
  }

  showAssignToRunDialogWithRemoveRun(selectedFreightOrders: FreightOrder[]) {
    const runId = this.state.run.id?.toString();
    this.setState({
      removeOrderFromRun: runId,
      showAssignToRunDialog: true,
      selectedItems: selectedFreightOrders,
      isAdditionalRunAssignmentDialog: false,
    });
  }

  removeOrdersFromRun(selectedFreightOrders: FreightOrder[]) {
    const selectedFreightOrdersIds = selectedFreightOrders.map(fo => {
      return fo.id;
    });

    MessageService.sendMessage(messages.removeOrderClicked);
    const stops = this.state.stops.filter(s => s !== null) ?? [];
    const tasks: Task[] = [];
    stops.forEach(stop => {
      const stopTasks =
        stop.tasks?.filter(t => t !== null && selectedFreightOrdersIds.includes(t.freight_order_id)) ?? [];
      tasks.push(...stopTasks);
    });

    this.deleteTasksAndUpdateOrders(tasks, selectedFreightOrders, this.state.run.id?.toString() ?? '');
  }

  async deleteTasksAndUpdateOrders(tasks: Task[], freightOrders: FreightOrder[], runId: string) {
    const tlePromises: Promise<void>[] = [];
    const orderPromises: Promise<void>[] = [];

    freightOrders.forEach(order => {
      tlePromises.push(this.createTle(order, this.createUnassignedTleEntity(order, runId.toString())));
    });

    this.deleteTasks(tasks)
      .then(() => {
        Promise.all(orderPromises);
        Promise.all(tlePromises);
        this.toastService?.showSuccess(this.props.toast, 'Orders removed from run successfully.');
        MessageService.sendMessage(messages.runOrdersRemoved);
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, Orders delete from run failed, please try again.');
      });
  }

  async deleteTasks(tasks: Task[]) {
    return Promise.all(
      tasks.map(async (task: Task) => {
        if (task.id) {
          return this.tasksService?.deleteTask(task.id.toString());
        }
      })
    );
  }

  async updateFreightOrderStage(freightOrder: FreightOrder, stage: FreightStage) {
    const state = localStorage.getItem('current state')!;
    const routePart = {...freightOrder.route![state], stage: stage};
    const route = {...freightOrder.route, [state]: routePart};

    const updatedOrder: FreightOrderPatch = {
      route: route,
    };

    await this.freightOrdersService?.updateFreightOrder(freightOrder.id?.toString() ?? '', updatedOrder);
  }

  createUnassignedTleEntity(freightOrder: FreightOrder, runId: string) {
    const tleLink: TleLink = {link_type: 'run', linked_id: runId};
    const content: TleContentUnassigned = {
      message: 'Order was un-assigned from a run',
      links: [tleLink],
    };

    return {
      event_type: 'unassigned',
      entity_type: 'order',
      recorded_by: this.getCurrentUserId(),
      entity_id: freightOrder.id ?? '',
      content: content,
      recorded_at: new Date(),
    } as TimeLineEvent;
  }

  async createTle(freightOrder: FreightOrder, tle: TimeLineEvent) {
    return this.tlesService!.createTle(tle).then(data => {
      if (data) {
        const updatedOrder: OrderPatch = {
          last_event_id: data.id,
        };
        this.ordersService?.updateOrder(freightOrder.id ?? '', updatedOrder);
      }
    });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  render() {
    const {run} = this.props;

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        {run.id && (
          <GroupOrdersComponent run={run} initMenuItems={freightOrders => this.initMenuItems(freightOrders)} />
        )}
        <AddOrdersToRunDialog
          showDialog={this.state.showAddOrderToRunDialog}
          onHide={() => this.setState({showAddOrderToRunDialog: false})}
          run={this.state.run}
          toast={this.props.toast}
        />
        <AssignOrdersToRunDialog
          showDialog={this.state.showAssignToRunDialog}
          onHide={() => this.setState({showAssignToRunDialog: false})}
          freightOrders={this.state.selectedItems}
          removeRunId={this.state.removeOrderFromRun}
          isAdditionalRunAssignment={this.state.isAdditionalRunAssignmentDialog}
        />
      </div>
    );
  }
}

export default RunOrders;
