import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {Vehicle} from 'two-core';
import VehiclesService from '../../services/VehiclesService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  vehicle?: Vehicle;
}

interface State {
  saving?: boolean;
  vehiclePatch?: Partial<Vehicle>;
}
export default class EditVehicleDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  vehiclesService?: VehiclesService;
  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);

    this.state = {saving: false, vehiclePatch: {}};
  }

  componentDidMount() {
    this.vehiclesService = this.context.vehiclesService;
    this.twoToast = this.context.twoToast;
  }

  onHide() {
    this.setState({
      saving: false,
      vehiclePatch: {},
    });
    this.props.onHide();
  }

  onSave() {
    const {vehicle} = this.props;
    const {vehiclePatch} = this.state;
    if (vehicle?.id) {
      if (!vehiclePatch?.name) {
        this.twoToast?.showError('Name is required');
        return;
      }
      if (vehiclePatch.name === vehicle.name) {
        this.twoToast?.showInfo('No changes');
        this.onHide();
        return;
      }
      this.updateVehicle(vehicle.id, vehiclePatch);
    } else {
      if (!vehiclePatch?.name) {
        this.twoToast?.showError('Name is required');
        return;
      }
      const updatedVehiclePatch = {...vehiclePatch, state_id: localStorage.getItem('current state')!};
      this.createVehicle(updatedVehiclePatch);
    }
  }

  async createVehicle(vehicePatch: Partial<Vehicle>) {
    this.setState({saving: true});
    try {
      const vehicle = await this.vehiclesService?.createVehicle(vehicePatch);
      if (!vehicle) {
        this.twoToast?.showError('Error creating vehicle');
        this.setState({saving: false});
        return;
      }
      this.twoToast?.showSuccess('Vehicle created');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error creating vehicle');
      this.setState({saving: false});
    }
  }

  async updateVehicle(vehicleId: number, vehiclePatch: Partial<Vehicle>) {
    this.setState({saving: true});
    try {
      const vehicle = await this.vehiclesService?.updateVehicle(vehicleId, vehiclePatch);
      if (!vehicle) {
        this.twoToast?.showError('Error updating vehicle');
        this.setState({saving: false});
        return;
      }
      this.twoToast?.showSuccess('Vehicle updated');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error updating vehicle');
      this.setState({saving: false});
    }
  }

  render() {
    const {showDialog, vehicle} = this.props;
    const {saving, vehiclePatch} = this.state;

    const header = vehicle?.id ? 'Edit Vehicle' : 'New Vehicle';
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
        <Button label={vehicle ? 'Save' : 'Create'} onClick={this.onSave} loading={saving} disabled={saving} />
      </div>
    );
    const content = (
      <div className="p-fluid w-100">
        <div className="p-field p-grid">
          <label className="p-col-2">name</label>
          <div className="p-col-10">
            <InputText
              value={vehiclePatch?.name !== undefined ? vehiclePatch.name : vehicle?.name}
              onChange={e => this.setState({vehiclePatch: {...vehiclePatch, name: e.target.value}})}
            />
          </div>
        </div>
      </div>
    );

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          header={header}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          {content}
        </TwoDialog>
      </>
    );
  }
}
