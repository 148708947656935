import React from 'react';
import {History} from 'history';
import {Location} from 'two-core';
import './LocationDetail.scss';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';
import {InputSwitch} from 'primereact/inputswitch';

interface Props {
  location: Location;
  history: History;
}

interface State {
  location: Location;
}

class LocationDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      location: {
        address: {
          country: '',
          lat: 0,
          long: 0,
          postCode: '',
          state: '',
          state_short: '',
          street: '',
          suburb: '',
          phoneNumber: '',
        },
        name: '',
        state_id: '',
        type: 'other',
      },
    };
  }

  componentDidMount() {
    this.setLocation(this.props.location);
  }

  setLocation(location: Location) {
    this.setState({
      location: location,
    });
  }

  render() {
    const {location} = this.state;
    let dealershipTitle = location.dealership?.name;
    if (location.dealership?.trading_as) {
      dealershipTitle += ` (${location.dealership.trading_as})`;
    }
    return (
      <>
        <EntityDetailField label="integration">{location?.name}</EntityDetailField>
        <EntityDetailField label="type">{location?.type}</EntityDetailField>
        <EntityDetailField label="state">{location?.state_id}</EntityDetailField>
        {!!dealershipTitle?.length && <EntityDetailField label="dealership">{dealershipTitle}</EntityDetailField>}
        <EntityDetailDivider />
        <EntityDetailField label="street">{location?.address.street}</EntityDetailField>
        <EntityDetailField label="suburb">{location?.address.suburb}</EntityDetailField>
        <EntityDetailField label="postcode">{location?.address.postCode}</EntityDetailField>
        <EntityDetailField label="state">
          {location?.address.state} / {location.address.state_short}
        </EntityDetailField>
        <EntityDetailField label="country">{location?.address.country}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="phone number">{location?.address.phoneNumber}</EntityDetailField>
        <EntityDetailField label="instructions">{location?.instructions}</EntityDetailField>
        <div className="p-grid">
          <label className="p-col-2">fitting</label>
          <div className="p-col-2">
            <InputSwitch checked={location.used_for_fitting} />
          </div>
          <label className="p-col-2">pick up</label>
          <div className="p-col-2">
            <InputSwitch checked={location.used_for_pickup} />
          </div>
          <label className="p-col-2">storage</label>
          <div className="p-col-2">
            <InputSwitch checked={location.used_for_storage} />
          </div>
        </div>
      </>
    );
  }
}

export default LocationDetail;
